import { throttle } from 'lodash';
import moment from 'moment';

import setLogs from '../../actions/apicall/setLogs';
import * as types from '../../actions/running/actionTypes';
import {
  DOOR_CLOSE_INDICATORS_VALUE,
  DOOR_OPEN_INDEX,
  DOOR_OPEN_INDICATORS_VALUE,
} from '../../common/constants';

const _ = require('lodash');

export interface WebSocketReducerState {
  vehicleLiveDataMap: { [key: string]: any };
  connections: { [key: string]: any };
  receiveData: { [key: string]: any };
  websocketListeners: any[];
  runningDataDetail: any[];
}
const defaultState = {
  vehicleLiveDataMap: {},
  connections: {},
  receiveData: {},
  websocketListeners: [],
  runningDataDetail: [],
};

// vehicle_idごとにAPI実行を管理
const apiThrottleList: {[key: string]: () => void} = {};
// 遅延検知の閾値(秒)
const DELAY_DETECTION_THRESHOLD = 10;
// 遅延検知のスロットル時間(ミリ秒)
const DELAY_DETECTION_THROTTLE = 10000;

/**
 * @description WSバージョン間の互換性保持
 * @param {Object} data 受診データ
 * @param {String} version データのバージョン
 * @param {Object} prevData 前回受信データ
 * @return {Object} v2のバージョンのデータ
 */
export function unifyData(data: any, version: any, prevData: any) {
  let { alerts, info } = data;
  const { timestamp, vehicle_id } = data;
  alerts = alerts || {};
  info = info || {};
  // アラートがある場合はを前回のデータを利用する
  if (Object.keys(alerts).length > 0) {
    return Object.assign(prevData, {
      alerts,
      has_alert: data.has_alert,
      timestamp,
      vehicle_id,
    });
  }
  // 車載からの通知がある場合も前回のデータを利用する
  if (Object.keys(info).length > 0) {
    return Object.assign(prevData, {
      info,
      timestamp,
      vehicle_id,
    });
  }

  switch (version) {
    case 'v2': {
      return data;
    }
    default: {
      let { service, vehicle, running, sensor, navya } = data;
      const { control } = data;

      service = service || {};
      vehicle = vehicle || {};
      running = running || {};
      sensor = sensor || {};
      navya = navya || {};

      return {
        alerts,
        action: control ? control.action : undefined,
        result: control ? control.result : undefined,
        is_connected: !!data.is_connected,
        additional_info: {
          ready_to_start: data.additional_info ? data.additional_info.ready_to_start : null,
        },
        service: {
          ...service,
          values: {
            stop_sequence: data.service?.values?.stop_sequence,
          },
          needs_stop_next: false,
        },
        has_alert: data.has_alert,
        vehicle_type_id: 1,
        sbd_system: data.sbd_system,
        vehicle_id: data.vehicle_id,
        timestamp: control ? control.timestamp : running.timestamp,
        vehicle: {
          values: {
            latitude: running.latitude || undefined,
            longitude: running.longitude || undefined,
            azimuth: running.azimuth,
            go_nogo: 0,
            speed: vehicle.speed || 0,
            engine_rpm: vehicle.engine_rpm,
            battery_soc: vehicle.battery,
            running_mode: running.mode === 0 ? 'auto' : 'manual',
            running_status: running.status,
            has_override: running.has_override === 1 ? 'on' : 'off',
            car_temperature: vehicle.car_temperature || 0,
            positioning_method: 'gnss',
            mileage: 2434,
            hit_ratio: navya.hit_ratio || undefined,
          },
          indicators: {
            shift_position: 'none',
            front_door:
              service.front_door === DOOR_OPEN_INDEX
                ? DOOR_OPEN_INDICATORS_VALUE
                : DOOR_CLOSE_INDICATORS_VALUE,
            back_door:
              service.back_door === DOOR_OPEN_INDEX
                ? DOOR_OPEN_INDICATORS_VALUE
                : DOOR_CLOSE_INDICATORS_VALUE,
          },
          hardwares: {
            gps: sensor.gps,
          },
        },
        integration: {
          control: null, // 制御
          obstacle_sensor: null, // 障害物センサー
          position_sensor: null, // 位置センサー
          network: null, // 通信
          power_system: null, // 原動機
          driving_system: null, // 駆動機
          braking_system: null, // ブレーキ
          energy_system: null, // エネルギー
        },
        easymile: data.easymile,
        navya: data.navya,
        info: data.info,
      };
    }
  }
}

export default function (state: WebSocketReducerState = defaultState, action: any) {
  switch (action.type) {
    case types.FETCH_RUNNING_DATA: {
      const { runningDataDetail } = action.payload;
      return Object.assign({}, state, {
        runningDataDetail,
      });
    }
    case types.OPEN_WEBSOCKET: {
      const { connections } = action.payload;

      return Object.assign({}, state, {
        connections,
      });
    }
    case types.CLOSE_WEBSOCKET: {
      const { connections } = state;

      // 全てのWebsocketをCloseする
      if (connections) {
        Object.keys(connections).forEach((key) => connections[key].close());
      }

      return Object.assign({}, state, {
        connections: {},
        websocketListeners: [],
      });
    }
    case types.RECIEVIED_MESSAGE_WEBSOCKET: {
      const { recieveData } = action.payload;
      const { websocketListeners, runningDataDetail, vehicleLiveDataMap } = state;
      let prevData: { [key: string]: any } = {};
      let data: { [key: string]: any } = {};
      try {
        data = JSON.parse(recieveData);
        prevData = vehicleLiveDataMap[data.vehicle_id] || {};
        if (!data.service) {
          data.service = prevData.service || {};
        }
        if (!data.vehicle) {
          data.vehicle = prevData.vehicle || {};
        }
        if (!data.sbd_system) {
          data.sbd_system = prevData.sbd_system || {};
        }
        if (!data.integration) {
          data.integration = prevData.integration || {};
        }
        console.info('live data', data);
      } catch (e) {
        data = {
          service: null,
          vehicle: null,
          control: null,
        };
      }

      // データをV2に統一
      const targetVehicleDetail = runningDataDetail.find(
        (detail) => detail.vehicle.vehicle_id === data.vehicle_id,
      );
      let apiVersion = 'v1';
      if (_.get(targetVehicleDetail, 'vehicle.api_version')) {
        apiVersion = targetVehicleDetail.vehicle.api_version;
      }

      data = unifyData(data, apiVersion, prevData);

      // 送信したデータの受信確認
      const copyWebsocketListeners = Object.assign([], websocketListeners);
      websocketListeners.forEach(({ match, action: listenerAction, matchRemove }, index) => {
        if (match(data)) {
          listenerAction(data);
          // マッチした場合、リスナーを削除する
          if (matchRemove) {
            copyWebsocketListeners.splice(index, 1);
          }
        }
      });

      const copiedVehicleLiveDataMap = Object.assign({}, vehicleLiveDataMap);
      if (data.vehicle_id && data.service && data.vehicle) {
        data.timestamp = moment();
        copiedVehicleLiveDataMap[data.vehicle_id] = Object.assign(
          {},
          copiedVehicleLiveDataMap[data.vehicle_id],
          data,
        );
      }

      // 遅延検知
      const vehicleLiveData: {[key: string]: any} = copiedVehicleLiveDataMap[data.vehicle_id];
      
      if (vehicleLiveData && vehicleLiveData.timestamp && vehicleLiveData.receive_timestamp) {
        const timestamp = moment(vehicleLiveData.timestamp);
        const receiveTimestamp = moment.unix(vehicleLiveData.receive_timestamp);
        const diff = timestamp.diff(receiveTimestamp, 'seconds');

        if (diff > DELAY_DETECTION_THRESHOLD) {
          if (!apiThrottleList[data.vehicle_id]) {
            // 初めて遅延が検知された場合はスロットル関数を設定する
            apiThrottleList[data.vehicle_id] = throttle(() => {
              // API実行
              setLogs();
            }, DELAY_DETECTION_THROTTLE);
          } else {
            // 2回目以降は作成済みのスロットル関数を実行する
            apiThrottleList[data.vehicle_id]();
          }
        }
      }

      return Object.assign({}, state, {
        vehicleLiveDataMap: copiedVehicleLiveDataMap,
        receiveData: data,
        websocketListeners: copyWebsocketListeners,
      });
    }
    case types.SEND_MESSAGE_WEBSOCKET: {
      // 所持している全てのWSにデータを送る
      const { messageData, vehicleID } = action.payload;
      const { connections } = state;
      Object.keys(connections).some((key) => {
        const connection = connections[key];
        const url = connection.currentURL;
        const hash = url.slice(1).split('&');
        const vehicles = hash.find((val: any) => val.indexOf('vehicles') >= 0);
        if (!vehicles) {
          return false;
        }
        const vehicleIds = vehicles.split('=')[1];

        if (vehicleIds.split(',').some((id: any) => id === (vehicleID || 0).toString())) {
          connection.sendMessage(JSON.stringify(messageData));
          return true;
        }
        return false;
      });
      return state;
    }
    case types.ADD_WEBSOCKET_LISTENER: {
      // websocketのリスナーを追加する
      const { websocketListener } = action.payload;
      return Object.assign({}, state, {
        websocketListeners: [...state.websocketListeners, websocketListener],
      });
    }
    case types.REMOVE_WEBSOCKET_LISTENER: {
      // websocketのリスナーを削除する
      const { websocketListener } = action.payload;
      const { websocketListeners } = state;
      websocketListeners.some(({ name: _name }, index) => {
        if (websocketListener.name === _name) {
          websocketListeners.splice(index, 1);
          return true;
        }
        return false;
      });
      return Object.assign({}, state, {
        websocketListeners,
      });
    }
    default:
      return state;
  }
}
