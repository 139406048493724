/**
 * @description
 * ロケーション検知
 *
 */
import PropTypes from 'prop-types';
import React, { lazy, Suspense } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';

function onLocationChange() {
  const page = window.location.pathname;
  const url = window.location.href;
  // if (url === window.lastestUrl) { // 同じページの遷移は出力しない。
  if (url === (window as any).lastestUrl) {
    // 同じページの遷移は出力しない。
    return;
  }
  // window.lastestUrl = url;
  (window as any).lastestUrl = url;

  (window as any).gtag('config', 'UA-110375728-2', { page_path: page });
}

interface Props {
  from: any;
  history: any;
}
const LocationListener: React.FC<Props> = ({ from, history = {} }: Props) => {
  onLocationChange();

  const Page = lazy(() => from);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Suspense fallback={<>...</>}>
      <Page history={history} navigate={navigate} location={location} />
    </Suspense>
  );
};

export default LocationListener;
// LocationListener.propTypes = {
//   from: PropTypes.object.isRequired,
//   history: PropTypes.object,
// };
// LocationListener.defaultProps = {
//   history: {},
// }
