import { GetAlertListPlaceholder } from '../../../actions/apicall/getAlertList';
import lang from '../../../lang';
import {
  ALERT_TYPE_DRIVINGSTANDUP,
  ALERT_TYPE_INCARALARM,
  ALERT_TYPE_SAFETY_BEFORE_AUTO_DRIVING,
} from '../../constants';
import { replacePlaceholders } from '../replacePlaceholders';

const locale = lang.getLanguage();

/**
 * @description アラートタイトルを生成します
 * @param {String} errorCode アラートのエラーコード
 * @param {String} alertAIType (オプショナル) AI系のアラートの場合はalert_ai_typeを指定する
 * @param {Array} codes アラート文言の配列
 * @returns アラートタイトル
 */
export default function createAlertTitle(
  errorCode: any,
  alertAIType: any,
  { codes: alertDefinition }: { codes: any },
  placeholder?: GetAlertListPlaceholder,
) {
  const matchedAlert = alertDefinition[errorCode];

  if (matchedAlert) {
    // 車両系のエラーの場合

    const parsedMessage = placeholder
      ? replacePlaceholders(matchedAlert.target[locale], placeholder[locale])
      : matchedAlert.target[locale];

    return {
      title: parsedMessage,
      subtitle: matchedAlert.description[locale],
    };
  } else if (alertAIType === ALERT_TYPE_DRIVINGSTANDUP) {
    return {
      title: lang.get('alertmap.alert-type-drivingstandup'),
      subtitle: '映像をご確認下さい。',
    };
  } else if (alertAIType === ALERT_TYPE_SAFETY_BEFORE_AUTO_DRIVING) {
    return {
      title: lang.get('alertmap.alert-type-safety-before-auto-driving'),
      subtitle: '映像をご確認下さい。',
    };
  } else if (alertAIType === ALERT_TYPE_INCARALARM) {
    return {
      title: lang.get('alertmap.alert-type-incaralarm'),
      subtitle: '映像をご確認下さい。',
    };
  }
  // 車両系エラーでもAI系エラーでもなければ不明を表示
  return {
    title: lang.get('common.unknown'),
    subtitle: '',
  };
}
