import { getAPIClientAsync } from '../../common/utils/aws';
import getAuthority from '../../common/utils/getAuthority';
import { CommonDataRes } from './apiGetResponse';

export interface GetVideoStreamEndpointReq {
  vehicleID: number;
}

export interface GetVideoStreamEndpointRes {
  data: {
    endpoint: string;
  } & CommonDataRes;
}

/**
 * @description 車載映像のストリームURLを取得する
 * @param {Number} vehicleID 車両ID
 */
export default async function getVideoStreamEndpoint({
  vehicleID,
}: GetVideoStreamEndpointReq): Promise<GetVideoStreamEndpointRes> {
  const { isCanAccessPersonalInfo } = getAuthority('common');
  if (!isCanAccessPersonalInfo) {
    return new Promise((resolve, reject) => {
      // reject(new Error({
      reject({
        data: {
          code: 'permission_denied',
          message: '権限がありません',
          status: 400,
        },
      });
    });
  }
  const apigClient = await getAPIClientAsync();
  const params = {
    vehicle_id: vehicleID || null,
  };

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.getVideoStreamEndpointGet(params, body, additionalParams);
}
