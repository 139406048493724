import { getAPIClientAsync } from '../../common/utils/aws';

export interface GetAgencyInfoRes {
  data: {
    agency_id: number;
    agency_name: string;
    official_name: string;
    url: string;
    fare_url: string;
    email: string;
    timezone: string;
    lang: string;
    zip_number: string;
    address: string;
    tel: string;
    president_name: string;
    president_pos: string;
    lat: number;
    lon: number;
    capabilities?: {
      is_hino?: number;
      is_passenger_analysis?: number;
      is_3d_permission?: number;
      is_recording?: number;
      auto_vehicle_alerttab?: number;
    };
  };
}

/**
 * @description 事業者情報を取得する
 */
export default async function getAgencyInfo(): Promise<GetAgencyInfoRes> {
  const apigClient = await getAPIClientAsync();
  const params = {};

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.getAgencyInfoGet(params, body, additionalParams);
}
