/**
 * @description
 * Reactのルーティング処理
 *
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAppendixReminder } from '../actions/appendixReminder';
import { startWatch } from '../actions/geolocation/geolocationAction';
import { initNotification, setAlertDefinition } from '../actions/notification/notificationAction';
import { fetchAgencyDefinition } from '../actions/running/runningAction';
import { initTwilio } from '../actions/twilio/TwilioAction';
import PageRouter, { DispatchToProps, StateToProps } from './RouterComponent';

export function mapDispatchToProps(dispatch: any): DispatchToProps {
  return bindActionCreators(
    {
      initTwilioAction: initTwilio,
      initNotificationAction: initNotification,
      setAlertDefinitionAction: setAlertDefinition,
      fetchAgencyDefinitionAction: fetchAgencyDefinition,
      startWatchAction: startWatch,
      getAppendixReminderAction: getAppendixReminder,
    },
    dispatch,
  );
}
export function mapStateToProps(state: any): StateToProps {
  return {
    alertDefinition: state.agencyDefinitionReducer.alert,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageRouter);
