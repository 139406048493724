import { ZenObservable } from 'zen-observable-ts/lib/types';

import * as types from '../../actions/infra/actionTypes';

export interface MqttReducerState {
  [key: string]: ZenObservable.Subscription;
}

const defaultState = {};

/**
 * 接続中のMQTTを保持する
 */
export default (state: MqttReducerState = defaultState, action: any = { type: '' }) => {
  switch (action.type) {
    case types.OPEN_MQTT:
      state[action.payload.key] = action.payload.mqtt;
      return state;
    case types.CLOSE_ALL_MQTT:
      // close MQTT
      Object.keys(state).forEach((key) => {
        state[key].unsubscribe();
      });
      return defaultState;
    default:
      return state;
  }
};
