/**
 * @summary PUSH通知アクション
 * @description PUSH通知関連のアクション
 */

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken,isSupported, onMessage } from 'firebase/messaging';

import { checkLogin } from '../../common/utils/aws';
import createAlertTitle from '../../common/utils/createAlertTitle';
import firebaseConfig from '../../firebase-config';
import lang from '../../lang';
import isOK from '../apicall/isOK';
import subscribeAlert, { SubscribeAlertPushRes } from '../apicall/subscribeAlertPush';
import * as actions from './actionTypes';

const SERVICE_WORKER_NAME = 'firebase-messaging-sw.js';

let _alertDefinition = { codes: '' };
let isInit = false;
let messaging: any;

/**
 * @description Notificationをクリックした際の制御
 */
function onClickRecieveNotification(dispatch: any, eventArgs: any) {
  switch (eventArgs.data.message) {
    case 'getErrorMessage': {
      // JSONからアラートメッセージを取得
      const { notificationOptions } = eventArgs.data;
      const { data } = notificationOptions;
      // Agencyの検証
      const pushAgencyId = parseInt(data.agency_id, 10) || 0;
      // if (window.loginUser.agency_id !== pushAgencyId) {
      if ((window as any).loginUser.agency_id !== pushAgencyId) {
        return;
      }
      // ログイン状態の場合のみnotificationを表示する。
      const result = checkLogin();
      if (!result) {
        return;
      }
      const alertMessage = createAlertTitle(data.error_code, data.alert_ai_id, _alertDefinition);
      notificationOptions.alertMessage = alertMessage;

      if (!navigator.serviceWorker.controller) {
        return;
      }
      navigator.serviceWorker.controller.postMessage({
        message: 'getErrorMessage',
        notificationTitle: eventArgs.data.notificationTitle,
        notificationOptions,
      });
      break;
    }
    case 'notificationclick': {
      const alertVehicleId = parseInt(eventArgs.data.vehicle_id, 10) || 0;
      dispatch({
        payload: {
          alertVehicleId,
        },
        type: actions.NOTIFICATION_CLICK,
      });
      break;
    }
    default:
      break;
  }
}

/**
 * @description ServiceWorkerからのメッセージ受信
 * @param {Object} dispatch Redux Action
 */
function onMessageServiceWorker(dispatch: any) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener(
      'message',
      onClickRecieveNotification.bind(null, dispatch),
    );
  }
}

/**
 * @description Subscribeしたトークンキーをサーバに送信
 * @param {Object} dispatch Redux Action
 * @param {String} token PUSH購読時に取得したトークン
 */
function sendToken(dispatch: any, token: any) {
  subscribeAlert(token)
    .then((result: SubscribeAlertPushRes) => {
      if (isOK(result)) {
        console.info('subscribe token send success');
        // 通知の購読完了
        dispatch({
          payload: {},
          type: actions.NOTIFICATION_SUBSCRIBE,
        });
      }
    })
    .catch((e) => {
      console.info('subscribe token send failed', e);
    });
}

/**
 * @description Service Workerの起動処理
 * @param {Object} dispatch Redux Action
 */
async function serviceWorkerAction(dispatch: any) {
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);

  navigator.serviceWorker
    .register(`/${SERVICE_WORKER_NAME}`)
    .then(async (registration: ServiceWorkerRegistration) => {
      const currentToken = await getToken(messaging, {
        serviceWorkerRegistration: registration,
        // vapidKey:
        //   'BGT5WjO-AWUZOHR5zVzVkQIk_InAkwX6ltaXKYT8a6c_pOC0vylnubxKpBGLRs2_cobIqridSg-BrmRnWlYx9vY'
      }).catch((error) => {
        console.info('Unable to get permission to notify.', error);
      });

      if (currentToken) {
        sendToken(dispatch, currentToken);
      } else {
        console.info(lang.get('dialog.notification.parmission_error'));
      }

      onMessageServiceWorker(dispatch);

      // フロントがアクティブの時はこのイベントが発火し、
      // バックグラウンドの場合はservice workerが発火する。
      onMessage(messaging, (payload: any) => {
        // Customize notification here
        const { data } = payload;
        const alertMessage = createAlertTitle(
          data?.error_code,
          data?.alert_ai_id,
          _alertDefinition,
        );
        data.alertMessage = alertMessage;
        const notificationOptions = {
          body: data.alertMessage.subtitle,
          // icon: '/favicon.png',
          data,
        };
        // Agencyの検証
        const pushAgencyId = parseInt(data.agency_id, 10) || 0;
        if (window.loginUser.agency_id !== pushAgencyId) {
          return null;
        }
        // OnMessageを受信する場合は画面がactive状態のため、ログインチェックは行わない。
        return navigator?.serviceWorker?.controller?.postMessage({
          message: 'getErrorMessage',
          notificationTitle: alertMessage.title,
          notificationOptions,
        });
      });
    })
    .catch((e) => {
      console.info(e);
    });
}

/**
 * @description プッシュ通知初期化
 */
export function initNotification() {
  if (!('serviceWorker' in navigator)) {
    // ブラウザがService workerに対応していない。
    return {
      payload: {},
      type: actions.SERVICE_WORKER_DISABLED,
    };
  }

  return async (dispatch: any) => {
    const supported = await isSupported().catch(() => false);

    if (!supported) {
      dispatch({
        payload: {},
        type: actions.SERVICE_WORKER_DISABLED,
      });
      return;
    }
    if (isInit) {
      dispatch({
        payload: {},
        type: actions.SERVICE_WORKER_INIT,
      });
      return;
    }
    isInit = true;

    try {
      await serviceWorkerAction(dispatch);
    } catch (ex) {
      console.info('service worker init error', ex);
    }

    dispatch({
      payload: {
        alertVehicleId: 0,
      },
      type: actions.SERVICE_WORKER_INIT,
    });
  };
}

/**
 * @description APIで取得したアラートメッセージ一覧をセットする
 * @param {Object} alertDefinition アラートメッセージ一覧
 * @returns なし
 */
export function setAlertDefinition(alertDefinition: any) {
  _alertDefinition = alertDefinition;

  return {
    payload: {},
    type: actions.ALERT_DEFINITION_INIT,
  };
}

/**
 * @description 通知されたPUSHをリセットする
 * @returns アラートが発生した車両IDを空に
 */
export function resetNotification() {
  return {
    payload: {
      alertVehicleId: 0,
    },
    type: actions.RESET_NOTIFICATION,
  };
}
