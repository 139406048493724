import { LANGUAGE_DRIVER_KEY, LANGUAGE_OPERATOR_KEY } from '../common/constants';

export default {
  /* moment */
  'moment.locale': 'fi',
  'moment.timezone': 'Europe/Helsinki',
  'moment.locale.weekdays': [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  'moment.locale.weekdaysShort': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  'moment.locale.weekdaysMin': ['sun', 'mon', 'thu', 'wen', 'thu', 'fri', 'sat'],

  /* ログイン画面 */
  'login.insufficient.input': 'There are unentered data items',
  'login.entered.value.policyerror':
    'Enter alphabetic characters (uppercase letters, lowercase letters), numbers, symbols and more than 10 digits',
  'login.entered.value.different': 'The entered value is different',
  'login.fail.account.not.registered': 'Login failed <br /> Account is not registered',
  'login.fail.account.policyerror': `The temporary password is incorrect or does not meet the password requirements <br />
  · 10 letters or more including alphabetic characters (upper case letters, lower case letters), numbers, symbols <br />
  · The password "^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \`" can be specified`,
  'login.session.timeout': 'Session Timeout <br /> please log in again',
  'login.retry.login': 'Login failed <br /> Sorry to trouble you but please log in again',
  'login.maintenance': 'Server maintenance is currently under way',
  'login.fail': 'Username or password is incorrect.',
  'login.fail.account.networkerror': 'The network is out of order. Please try again later.',
  'login.fail.cognito_undefined': 'The agency name is error. Please specify the correct URL.',
  'login.footer.sp_other_info': 'Click here for other detailed information',
  'login.footer.detail_info': 'Detail Info',
  'login.footer.home_page': 'BOLDLY Co., Ltd. Homepage',
  'login.footer.security_policy': 'Information security policy',
  'login.footer.site_policy': 'Site Policy',
  'login.footer.parsonal_info': 'Handling of personal information',
  'login.footer.disclaimer': 'Disclaimer',
  'login.footer.operating_environment': 'Recommended operating environment',
  'login.footer.unregistered_partner': 'Unregistered partner',
  'login.footer.sp_please_contact': 'Please contact BOLDLY Inc.',
  'login.footer.boldly_hp': 'BOLDLY HP',

  'login.auth.sign_in.username': 'Username',
  'login.auth.sign_in.username.placeholder': 'Input',
  'login.auth.sign_in.pw': 'Password',
  'login.auth.sign_in.pw.placeholder_pc':
    'At least 10 digits, including letters (upper and lower case), numbers and symbols',
  'login.auth.sign_in.pw.placeholder_sp': 'Input',
  'login.auth.sign_in.pw.annotation':
    'At least 10 digits, including letters (upper and lower case), numbers and symbols',
  'login.auth.sign_in.button_login': 'Login',

  'login.auth.setup_totp.init': 'Initalize',
  'login.auth.setup_totp.title': 'Two-factor authentication setup start',
  'login.auth.setup_totp.description':
    'Two-factor authentication is required to use your account securely.',
  'login.auth.setup_totp.info_text1':
    '1. Install and open the Google Authenticator app on your smartphone',
  'login.auth.setup_totp.link_appdownload': 'App Install',
  'login.auth.setup_totp.info_text2':
    '2. Start the camera from the "Scan QR code" button in the app and scan the QR code',
  'login.auth.setup_totp.link_sercret1': 'Those who cannot read the QR code',
  'login.auth.setup_totp.link_sercret2': 'Click here to enter the setup key',
  'login.auth.setup_totp.button_next': 'Next',
  'login.auth.setup_totp.title_sp': 'Initialize',
  'login.auth.setup_totp.description_sp': `Initial settings have not been completed
  Make initial settings from your computer
  Please login again`,
  'login.auth.appdownload.title': 'Install Google Authenticator App',
  'login.auth.appdownload.description': 'Install and open the app from the QR code',
  'login.auth.secret_key.description1': 'From the setup key input button',
  'login.auth.secret_key.description2': 'Please enter the setup key.',
  'login.auth.secret_key.copy': 'Copy Code',
  'login.auth.force_new_password.new_pw': 'New Password',
  'login.auth.force_new_password.new_pw.placeholder_pc':
    'At least 10 digits, including letters (upper and lower case), numbers and symbols',
  'login.auth.force_new_password.new_pw.placeholder_sp': 'Input',
  'login.auth.force_new_password.new_pw.annotation':
    'At least 10 digits, including letters (upper and lower case), numbers and symbols',
  'login.auth.force_new_password.new_pw_re': 'New password (retype)',
  'login.auth.force_new_password.button_login': 'Login',
  'login.auth.confirm_totp.title': 'One-time password authentication',
  'login.auth.confirm_totp.description1': 'Open Google Authenticator App',
  'login.auth.confirm_totp.description2': 'Please enter a 6-digit numeric password.',
  'login.auth.confirm_totp.retry_message':
    'Enter the number again after the authentication token value has changed',
  'login.auth.confirm_totp.totp': 'one-time password',
  'login.auth.confirm_totp.totp.placeholder': '6 digits (half-width spaces not required)',
  'login.auth.confirm_totp.button_auth': 'Auth',
  'login.auth.confirm_totp.button_retry': 'start over',
  'login.auth.confirm_totp.session_timeout': `Your session has expired. <br />I'm sorry, but please try again from the beginning.`,
  'login.auth.send_user_name.mail': 'mail address',
  'login.auth.send_user_name.title': 'Forgot your username?',
  'login.auth.send_user_name.description1': 'Please enter your registered email address.',
  'login.auth.send_user_name.description2': 'Submit your username.',
  'login.auth.reset_password.username.placeholder': 'input',
  'login.auth.reset_password.username.button.submit': 'send',
  'login.auth.reset_password_complete.title1': 'A temporary password has been sent to',
  'login.auth.reset_password_complete.title2': 'your registered email address',
  'login.auth.reset_password_complete.title_user': 'Your username has been sent',
  'login.auth.reset_password_complete.description1': 'Please log in according ',
  'login.auth.reset_password_complete.description2': 'to the content of the sent email.',
  'login.auth.footer.contact': 'Contact',
  'login.auth.footer.password_reset': 'Forgot Password',

  'login.other_lang': 'japanese',
  'login.other_lang_value': 'jp',
  'login.auth.footer.send_username': 'Forgot Username',
  'login.no.information': 'There is no information to display',
  'login.error.information': 'Notification information could not be retrieved',
  'login.getting.information': 'Fetching notifications',
  'login.no.agency.message':
    'The business ID is not set in the URL. \nPlease enter your business ID.',

  /* 運行中車両一覧 */
  'map.title': 'Overview',
  'map.minutes': 'min',
  'map.style.normal': 'MAP',
  'map.style.satellite': 'SAT',
  'map.style.normal.describe': 'Map',
  'map.style.satellite.describe': 'Satellite',

  /* お知らせ一覧 */
  'information.info': 'Information',
  'information.additional': 'Additional',
  'information.bug': 'Bug/Problem',
  'information.maintenance': 'Maintenance',
  'information.campaign': 'Campaign',
  'information.other': 'Other',
  'information.close': 'Close',
  'information.oldinfo': 'OLD Information',
  'information.button.select': 'Update',
  'information.message.error_fetch_information': 'Fetching information failed.',

  /* ヘッダ及び通話ダイアログ */
  'calldialog.vehicle_name_acquiring': 'Acquiring',
  'calldialog.vehicle_name_error': 'Error',
  'calldialog.call_hangup': 'Hangup',
  'calldialog.call_receiving': 'Receiving',

  /* サイドバー */
  'sidebar.dashboard': 'Dashboard',
  'sidebar.map': 'Map',
  'sidebar.schedule': 'Assignment',
  'sidebar.demand': 'Demand',
  'sidebar.reservation': 'Reservations',
  'sidebar.reports': 'Reports',
  'sidebar.settings': 'Settings',
  'sidebar.alertmap': 'Analysis',

  /* ダッシュボード */
  'dashboard.title': 'Dashboard',
  'dashboard.select_driver': 'Select Driver',
  'dashboard.welcome1.morning': 'Good Morning, ',
  'dashboard.welcome1.daytime': 'Hello, ',
  'dashboard.welcome1.evening': 'Good Evening ',
  'dashboard.welcome2': '',
  'dashboard.summary': 'Summary',
  'dashboard.alert_category_name_error': 'Error',
  'dashboard.incomplete_alert1': 'Incomplete alert',
  'dashboard.incomplete_alert2': '',
  'dashboard.incomplete_alert_all': 'View All',
  'dashboard.arr': 'arr',
  'dashboard.dep': 'dep',
  'dashboard.route_name': 'Route Name',
  'dashboard.trips': 'Trips',
  'dashboard.today_schedule': "Today's Schedule",
  'dashboard.no_data': 'No Data',
  'dashboard.stoppage_time': 'Stop Time',
  'dashboard.loading': '-',
  'dashboard.datetime': 'Datetime',
  'dashboard.vehicles.type': 'Vehicles Type',
  'dashboard.message.error_fetch_untouched_alerts': 'Fetching untouched alerts failed.',
  'dashboard.message.error_fetch_todays_course': 'Fetching todays course failed.',
  'dashboard.message.error_fetch_employees': 'Fetching employees failed.',

  /* 運行中車両画面 */
  'in_operation_vehicle.arr': 'Arr.',
  'in_operation_vehicle.dep': 'Dep.',
  'in_operation_vehicle.control.internal_call': 'Internal Call',
  'in_operation_vehicle.control.external_call': 'External Call',
  'in_operation_vehicle.control.start': 'Start',
  'in_operation_vehicle.control.stop': 'Stop',
  'in_operation_vehicle.control.pause': 'Pause',
  'in_operation_vehicle.control.deceleration.start': 'deceleration',
  'in_operation_vehicle.control.complete': 'complete',
  'in_operation_vehicle.control.deceleration.complete1': 'complete',
  'in_operation_vehicle.control.deceleration.complete2': '',
  'in_operation_vehicle.control.departure.start': 'departure',
  'in_operation_vehicle.control.departure.complete1': 'complete',
  'in_operation_vehicle.control.departure.complete2': '',
  'in_operation_vehicle.section_title.route_location': 'Operation Information',
  'in_operation_vehicle.section_title.speed_occupancy': 'Speed and Occupancy',
  'in_operation_vehicle.section_title.shuttle_information': 'Shuttle System Information',
  'in_operation_vehicle.section_title.drive_information': 'Drive Information',
  'in_operation_vehicle.liveview.no_camera': 'No Camera',
  'in_operation_vehicle.alert.urgent_task': 'Urgent Task',
  'in_operation_vehicle.alert.first': 'First',
  'in_operation_vehicle.alert.update': 'Update',
  'in_operation_vehicle.alert.title': 'Anomaly Detection Information',
  'in_operation_vehicle.button.show_cameras': 'Show Camera List',
  'in_operation_vehicle.running.mode.manual': 'Manual',
  'in_operation_vehicle.running.mode.auto': 'Auto',
  'in_operation_vehicle.running.mode.override': 'override',
  'in_operation_vehicle.display.normal': 'Normal',
  'in_operation_vehicle.display.simulator': 'Simulator',
  'in_operation_vehicle.people': '',
  'in_operation_vehicle.trips.arr': 'Arr',
  'in_operation_vehicle.trips.dep': 'Dep',
  'in_operation_vehicle.miniute': 'min',
  'in_operation_vehicle.miniute_delay': 'delay',
  'in_operation_vehicle.eta': 'min(ETA)',
  'in_operation_vehicle.camera-1': 'camera 1',
  'in_operation_vehicle.camera-2': 'camera 2',
  'in_operation_vehicle.camera-3': 'in-car video',
  'in_operation_vehicle.camera-4': 'out-car video',
  'in_operation_vehicle.message.confirm_stop_shuttle':
    'Are you sure you want to stop this shuttle?',
  'in_operation_vehicle.message.confirm_start_shuttle':
    'Are you sure you want to let this shuttle run?',
  'in_operation_vehicle.message.confirm_shuttle': 'Are you sure you want to let this shuttle?',
  'in_operation_vehicle.message.no_support_browser':
    'Your browser does not support the type of this video.',
  'in_operation_vehicle.message.no_video': 'No video',
  'in_operation_vehicle.message.notify_stop_shuttle': 'Sent a stop instruction to this shuttle',
  'in_operation_vehicle.message.faild_stop_shuttle': 'faild stop shuttle',
  'in_operation_vehicle.message.faild_start_shuttle': 'faild start shuttle',
  'in_operation_vehicle.connected': 'Connected',
  'in_operation_vehicle.disconnected': 'Disconnected',
  'in_operation_vehicle.poweroff': 'Power off',
  'in_operation_vehicle.course_name': 'Course: ',
  'in_operation_vehicle.parent_route_short_name': 'Parent Route: ',
  'in_operation_vehicle.trip_name': 'Trip: ',
  'in_operation_vehicle.current_location': 'Current: ',
  'in_operation_vehicle.location_indent': '　　　',
  'in_operation_vehicle.resolver_img': 'batch_user_en.svg',
  'in_operation_vehicle.running.mode.auto.driving': 'Auto Driving',
  'in_operation_vehicle.running.mode.manual.driving': 'Manual Driving',
  'in_operation_vehicle.goto_detail': 'details',
  'in_operation_vehicle.controll.button.emergency': 'emergency',
  'in_operation_vehicle.controll.button.admission': 'admission',
  'in_operation_vehicle.controll.button.orbiting': 'orbiting',
  'in_operation_vehicle.controll.button.pause': 'pause',
  'in_operation_vehicle.controll.button.exit': 'exit',
  'in_operation_vehicle.controll.button.emergency.landing': 'emergency landing',
  'in_operation_vehicle.controll.button.missionCancel': `Destination\ncancel`,
  'in_operation_vehicle.controll.button.connectionReset': `Destination\nInitialization`,
  'in_operation_vehicle.controll.button.gohome': 'go home',
  'in_operation_vehicle.controll.button.landing': 'landing',
  'in_operation_vehicle.controll.button.takeoff': 'take off',
  'in_operation_vehicle.audio.disabled': 'Audio could not be played.',
  'in_operation_vehicle.video.disabled': 'Video could not be played.',
  'in_operation_vehicle.camera.disabled': 'Failed to connect to the camera.',
  'in_operation_vehicle.server.disabled': 'Failed to connect to the video streaming server.',

  /* 運行スケジュール画面 */
  'calendar.title': '{0} / vehicle assignment',
  'calendar.subtitle.course_assign': 'Course Assignment Information',
  'calendar.trip': 'Trip',
  'calendar.course_id': 'CourseID',
  'calendar.course_name': 'Course Name',
  'calendar.start_time': 'Start Time',
  'calendar.end_time': 'End Time',
  'calendar.hours_worked': 'Hours Worked',
  'calendar.interval': 'Interval',
  'calendar.break_time': 'Break Time',
  'calendar.employee': '{0}',
  'calendar.shuttle_type': 'Shuttle Type',
  'calendar.arr': 'Arr.',
  'calendar.dep': 'Dep.',
  'calendar.next_day_abbreviation': 'Next Day',
  'calendar.supervisor': 'Supervisor',
  'calendar.patrol_interval': 'Interval(min.)',
  'calendar.blocking_reservation': 'reservation blocking',

  'calendar.error.empty_trips': 'There are no flights on the route. Please set the flight',
  'calendar.error.empty_drive_date': 'No operating date specified',
  'calendar.error.bad_format_drive_date': 'The specified flight date is incorrect',
  'calendar.error.duplicate_employee_id': 'The same employee cannot be assigned',
  'calendar.error.duplicate_vehicle_id': 'The same vehicle cannot be assigned',
  'calendar.error.bigin_time_not_found': 'No start time found',
  'calendar.error.end_time_not_found': 'No end time was found',
  'calendar.error.duplicate_time': 'Duplicate vehicle hours',

  /* 運行予約画面 */
  'reservation.tab.title': 'route',
  'reservation.tab.title.ondemand': 'ondemand',
  'reservation.tab.title.other': 'other',
  'reservation.parking': 'Parking',
  'reservation.button.earliest': 'Earliest',
  'reservation.title': 'Reservation',
  'reservation.schedule': 'Schedule',
  'reservation.departure_stop': 'Departure',
  'reservation.departure_time': 'Start time',
  'reservation.departure_point': 'Start point',
  'reservation.arrival_stop': 'Arrival',
  'reservation.arrival_time': 'End time',
  'reservation.arrival_point': 'End point',
  'reservation.drive_date': 'Drive Date',
  'reservation.vehicle_name': 'Vehicle Name',
  'reservation.capacity': 'Capacity',
  'reservation.driver': 'Driver',
  'reservation.detail': 'Reservation Detail',
  'reservation.detail.search': 'Search',
  'reservation.detail.available.vehicle_number': 'Vehicle Number',
  'reservation.detail.available.vehicle_name': 'Vehicle Name',
  'reservation.detail.available.total_passengers': 'Total Passengers',
  'reservation.detail.available.arrival_time': 'Arrival Time',
  'reservation.detail.available.no_data': 'No Result, Please search by other conditions.',
  'reservation.driver_assign': 'Driver Assign',
  'reservation.display_course': 'Display Course',
  'reservation.passenger_info': 'Passenger Info',
  'reservation.select_vehicle': 'Select Vehicle',
  'reservation.passenger.name': 'Name',
  'reservation.passenger.name.placeholder': 'Input Name',
  'reservation.passenger.tel': 'Contact',
  'reservation.passenger.passenger': 'Passenger',
  'reservation.passenger.representative': 'Representative',
  'reservation.passenger.representative.placeholder': 'Input Name',
  'reservation.check': 'Check',
  'reservation.check.before_operation': 'Before Operation',
  'reservation.check.before_operator': 'Operator',
  'reservation.check.before_operation_date': 'Operation Date',
  'reservation.check.after_operation': 'After Operation',
  'reservation.check.after_operator': 'Operator',
  'reservation.check.after_operation_date': 'Operation Date',
  'reservation.check.check_confirmation': 'Check Confirmation',
  'reservation.check.area': 'Area',
  'reservation.category': 'Category',
  'reservation.content': 'Contents',
  'reservation.start_time': 'Start time',
  'reservation.end_time': 'End time',

  'reservation.passenger.dialog.title': 'Passenger Information',
  'reservation.passenger.dialog.describe1': 'Passenger information can be registered.',
  'reservation.passenger.dialog.describe2':
    'Boarding, alighting, name, and number of persons are required fields.',
  'reservation.passenger.dialog.boarding': 'Boarding',
  'reservation.passenger.dialog.alighting': 'Alighting',
  'reservation.passenger.dialog.name': 'Name',
  'reservation.passenger.dialog.number_of_person': 'Number of persons',
  'reservation.passenger.dialog.tel': 'Tel',
  'reservation.passenger.dialog.subscriber': 'Subscriber',

  /* 予約一覧画面 */
  'reservation.passenger.list.csv.button': 'Click here to download CSV',
  'reservation.passenger.list.csv.downloading': 'CSV downloading...',
  'reservation.passenger.list.csv.error': 'CSV downloading Failed.',
  'reservation.passenger.list.area': 'Area',
  'reservation.passenger.total': 'Total',
  'reservation.passenger.check': 'Call・ODD・Device Check',
  'reservation.passenger.mode.day': 'Daily Mode',
  'reservation.passenger.mode.analysis': 'Analysis Mode',

  'reservation.preset': 'Preset',
  'reservation.preset.button.regist': 'Regist Preset',
  'reservation.preset.button.load': 'Load Preset',
  'reservation.preset.dialog.load.button': 'Load',
  'reservation.preset.dialog.load.description':
    'You can read and enter the details of a registered reservation.',
  'reservation.preset.dialog.regist.button': 'Regist',
  // eslint-disable-next-line max-len
  'reservation.preset.dialog.regist.description':
    'You can register the reservation details you have already entered and read and re-enter them later.',
  'reservation.preset.title': 'Reservation Preset',
  'reservation.preset.category': 'category',
  'reservation.preset.name': 'Preset Name',
  'reservation.preset.operator': 'Operator',
  'reservation.preset.operation_date': 'Operation Date',
  'reservation.category.route': 'Route Reservation',
  'reservation.category.ondemand': 'Ondemand Reservation',
  'reservation.category.semidemand': 'Semidemand Reservation',
  'reservation.day_preset.button.load': 'Load Day Preset',
  'reservation.day_preset.dialog.title': 'Load Day Preset',
  'reservation.day_preset.dialog.desc': 'You can recall the 1-day preset.',
  'reservation.day_preset.dialog.detail.title.name': 'Reservation Preset Name',
  // eslint-disable-next-line max-len
  'reservation.day_preset.dialog.isValidPreset':
    'This preset cannot be applied because the parking information associated with the vehicle is duplicated.',
  'reservation.day_preset.regist.dialog.isValidPreset':
    'There is an inconsistency in departure times between parking information.',
  'reservation.day_preset.regist.dialog.title': '1 day preset creation',
  'reservation.day_preset.regist.dialog.desc': 'You can create and edit daily presets.',
  'reservation.day_preset.regist.dialog.select_desc': 'Please select a reserved preset',

  'reservation.error.empty_vehicle1': 'The reservation could not be prepared.',
  'reservation.error.empty_vehicle2': 'Please search again at a different time.',
  'reservation.error.empty_agency_id': 'Could not get agency information',
  'reservation.error.not_match_reservation_key': 'reservation key not found',
  'reservation.error.passeneger_num_is_over_capacity': 'passeneger num is over capacity',
  'reservation.error.reservation_no_passenger1': 'There is no customer information.',
  'reservation.error.reservation_no_passenger2': '',
  'reservation.error.invalid_drive_date': 'Invalid Drive Date',
  'reservation.error.use_type_is_invalid': 'use_type is invalid',
  'reservation.error.not_match_parking_id': 'parking_id undefined',
  'reservation.error.use_type_or_agency_id_invalid': 'use_type or agency_id invalid',
  'reservation.error.trip_setting_not_enough': 'trip setting not enough',
  'reservation.error.alraedy_exist_another_reservation': 'alraedy exist another reservation',
  'reservation.error.reservation_is_not_accepted': 'trip request over range',
  'reservation.error.passenger_key_not_found': 'passenger key not found',
  'reservation.error.alraedy_exist_another_employee_assign':
    'alraedy exist another employee assign',
  'reservation.error.not_exist_travel_data': 'not exist travel times',
  'reservation.error.ask_boldly': 'please contact BOLDLY',
  'reservation.error.travel_data_system_error':
    'An error occurred while getting the time between waypoints.',
  'reservation.error.please_retry': 'please try again later',
  'reservation.error.partial_registration_completed':
    'Some reservations could not be applied. Please register the reservations that could not be registered individually.',
  'reservation.error.require_vehicle': 'Vehicle is required',
  'reservation.error.exist_relation_data': 'Unable to delete operator assigned to reservation',
  'reservation.area.information.enable': '*This flight can be reserved from LINE',
  'reservation.area.information.disable_1': '*This flight is currently available on LINE',
  'reservation.area.information.disable_2': 'Reservation is not possible. ',
  'reservation.area.information.disable_3': 'Change settings',
  'reservation.area.information.disable_4': 'here',
  'reservation.area.information.disable_5': 'from',
  'reservation.area.information.time_1': 'Reservation: ',
  'reservation.area.information.time_2': '~',

  /* 予約プリセット設定画面 */
  'reservation.setting.button': 'move to Preset Settings',
  'reservation.setting.title': 'Preset Settings',
  'reservation.setting.description': 'Manage and view reservation presets',
  'reservation.setting.dialog.description': 'You can change the name of the preset',
  'reservation.setting.busstop_name': 'Busstop Name',
  'reservation.setting.busstop_time': 'Arrival time',
  'reservation.setting.table.th.date': 'Register date',
  'reservation.setting.table.th.preset': 'Preset',
  'reservation.setting.table.th.register': 'Register',
  /* 削除前1日プリセット一覧画面 */
  'reservation.day.list.title': '1-day preset list',
  'reservation.day.list.desc1': 'Since it is registered in the 1-day preset, it cannot be deleted.',
  'reservation.day.list.desc2':
    'Delete or replace the target reserved preset from the 1-day preset.',
  'reservation.day.list.header1': '1-day preset name',
  'reservation.day.list.complete': 'You can delete the target reserved preset.',

  /* 1日プリセット設定画面 */
  'reservation.day.setting.button': 'move to Day Preset Settings',
  'reservation.day.setting.title': 'Day Preset Settings',
  'reservation.day.setting.description': 'Manage and view day reservation presets',

  /* レポート一覧画面 */
  'statistics.title': 'Report List',
  'statistics.sidebar_title': 'Report Settings',
  'statistics.showing_item': 'Select/Order Items',
  'statistics.showing_item.standard': 'Standard Items',
  'statistics.showing_item.running_management': 'Running Management Items',
  'statistics.showing_item.sales_analysis': 'Sales Management Items',
  'statistics.showing_item.shuttle_management': 'Shuttle Management Items',
  'statistics.speed': 'Speed',
  'statistics.rpm': 'RPM',
  'statistics.alert': 'Alert',
  'statistics.placeholder': 'Please select',
  'statistics.passenger_number': 'Passenger Number',
  'statistics.travel_distance': 'Travel Distance',
  'statistics.sales': 'Sales',
  'statistics.running_mode': 'Running Mode',
  'statistics.wheelchair': 'Wheelchair',
  'statistics.acceleration': 'Acceleration',
  'statistics.temperature': 'Temperature',
  'statistics.network_intensity': 'Network Intensity',
  'statistics.transmit_data': 'Transmit Data',
  'statistics.receive_data': 'Receive Data',
  'statistics.yourself': 'Yourself',
  'statistics.employee': 'Employee',
  'statistics.shuttle': 'Shuttle',
  'statistics.course': 'Course',
  'statistics.alert_priority': 'Alert Priority',
  'statistics.alert_priority.low': 'Low',
  'statistics.alert_priority.middle': 'Middle',
  'statistics.alert_priority.high': 'High',
  'statistics.alert_priority.priority_low': 'Priority Low',
  'statistics.alert_priority.priority_middle': 'Priority Middle',
  'statistics.alert_priority.priority_high': 'Priority High',
  'statistics.alert_priority.no_alert': 'No Alert',
  'statistics.running_mode.auto_steering': 'Auto Steering',
  'statistics.running_mode.manual_steering': 'Manual Steering',
  'statistics.running_mode.auto_brake': 'Auto Brake',
  'statistics.running_mode.manual_brake': 'Manual Brake',
  'statistics.running_mode.auto_or_manual': 'Auto Drive/Manual Drive',
  'statistics.running_mode.auto_drive': 'Auto Drive',
  'statistics.running_mode.manual_drive': 'Manual Drive',
  'statistics.running_mode.no_drive': 'No Drive',
  'statistics.equip_or_no': 'Equip or No',
  'statistics.button.select': 'Select',
  'statistics.switch_button.a_day': '1-Day',
  'statistics.switch_button.period': 'Period',
  'statistics.placeholder.require_select': 'Select one or more',
  'statistics.message.all_delete': 'Delete All',
  'statistics.message.no_result': 'No result',
  'statistics.message.no_data': 'No data',
  'statistics.message.from_to_error': 'Invalid period specification',
  'statistics.type.course': 'course analysis',
  'statistics.type.driver': '{0} analysis',

  /* 設定画面 */
  'settings.title': 'Settings',
  'settings.table_title.shuttle': 'Shuttles',
  'settings.table_title.timetable_revision': 'Time Schedule Revision',
  'settings.table_title.company': 'Basic information',
  'settings.table_title.others': 'Others',
  'settings.shuttle_list': 'Shuttle',
  'settings.parentroute_route': 'Parent Routes/Routes Trip',
  'settings.course': 'Courses ',
  'settings.fare': 'Fares',
  'settings.busstop': 'Bus Stops',
  'settings.languages': 'Languages',
  'settings.employee_list': 'Employee',
  'settings.office_list': 'Office',
  'settings.company_info': 'Company Info',
  'settings.check_presets': 'Check Presets',
  'settings.route_setting': 'Route Settings',
  'settings.workshifts_setting': 'Work Shift Settings',
  'settings.bus_shared_format': '',
  'settings.gtfs_title': 'GTFS.ZIP',
  'settings.log': 'Log',
  'settings.import': 'Import',
  'settings.help': 'Help',
  'settings.export': 'Export',
  'settings.after_ph2': 'After Ph2',
  'settings.add.manual': 'Manual Registration & Edit',
  'settings.add.format': 'Standard GTFS',
  'settings.add.office_text':
    'Be sure to register before registering Time Schedule Revision. Editing after registration is also possible.',
  'settings.add.bus_text1': 'You can register information about Time Schedule Revision.',
  'settings.add.bus_text2': 'It is also possible to register all together in Standard GTFS file.',
  'settings.add.single_add': 'Individual registration',
  'settings.add.all_add': 'Bulk registration',

  /* 系統一覧画面 */
  'parent_routes.title': 'Parent Route List',
  'parent_routes.edit_title': 'Details',
  'parent_routes.description1': 'Using the registered bus stop, you can manage and view the line.',
  'parent_routes.description2':
    'You can manage and view routes, flights by clicking on the added line.',
  'parent_routes.route_number': 'Route Number',
  'parent_routes.abbreviation': 'Abbreviation',
  'parent_routes.official_name': 'Official Name',
  'parent_routes.forwarding_flag': 'Forwarding',
  'parent_routes.description': 'Description',
  'parent_routes.color': 'Color',
  'parent_routes.font_color': 'Font Color',
  'parent_routes.startpoint': 'StartPoint',
  'parent_routes.endpoint': 'EndPoint',
  'parent_routes.via': 'Via',
  'parent_routes.route_list': 'Route list',
  'parent_routes.to_route_button': 'Route List',
  'parent_routes.message.require_abbreviation': 'Abbreviation is required',
  'parent_routes.message.max_length_abbreviation': 'Abbreviation must be less than 50 digits',
  'parent_routes.message.max_length_official_name': 'Official Name must be less than 128 digits',
  'parent_routes.message.max_length_description': 'Description must be less than 256 digits',
  'parent_routes.message.require_startpoint': 'StartPoint is required',
  'parent_routes.message.require_endpoint': 'EndPoint is required',
  'parent_routes.message.require_busstop': 'Input is required',
  'parent_routes.message.busstop_not_exist': 'This BusStop does not exist',

  /* 経路一覧画面 */
  'routes.title': 'Route List',
  'routes.edit_title': 'Route Editing Window',
  'routes.description1': 'You can manage and view routes using registered lines.',
  'routes.description2': 'By clicking on the added route you can manage and view the flights.',
  'routes.button.set_trip': 'Set Trip',
  'routes.button.reverse_trip': 'Reverse Trip',
  'routes.parent_route': 'Parent Route',
  'routes.abbreviation': 'Abbreviation',
  'routes.official_name': 'Official Name',
  'routes.route_number': 'Route Number',
  'routes.route_name': 'Route Name',
  'routes.section': 'Section',
  'routes.via': 'Via',
  'routes.busstops': 'BusStops',
  'routes.notice': 'Notice ID',
  'routes.local_route_id': 'Local Route ID',
  'routes.notice_busstop': 'Notice busstop',
  'routes.notice_local_route_id': 'Notification',
  'routes.notice_local_route_id_yes': 'Yes',
  'routes.notice_local_route_id_no': 'No',
  'routes.start_point': 'Start point',
  'routes.end_point': 'End point',
  'common.route.edit': 'Set Route',
  'routes.message.inconsistency_busstops':
    'There is an inconsistency between BusStops and Parent Route.',
  'routes.message.require_route_number': 'Route Number is required',
  'routes.message.max_length_route_number': 'Route Number must be less than 50 digits',
  'routes.message.require_route_name': 'Route Name is required',
  'routes.message.max_length_route_name': 'Route Name must be less than 128 digits',
  'routes.message.least_busstops': 'Route must contain at least two BusStops',
  'routes.message.is_hankaku_notice':
    'You can only enter half-width alphanumeric characters for the Notice ID',
  'routes.message.max_length_notice': 'Notice ID must be less than 128 digits',
  'routes.message.is_hankaku_local_route_id':
    'You can only enter half-width alphanumeric characters for the ID',
  'routes.message.max_length_local_route_id': 'Local route ID must be less than 128 digits',

  /* 便設定画面 */
  'trips.title': 'Trip List',
  'trips.edit_title': 'Trip Setting',
  'trips.button.add_trip': 'Add new Trip',
  'trips.header.route_number': 'Route Number',
  'trips.header.route_name': 'Route: ',
  'trips.next_day': 'Next',
  'trips.button.add_trip-2': 'You can add flights to the route.',
  'trips.route_id': 'Traffic classification',
  'trips.arr': 'Arr.',
  'trips.arr_start': 'Arr(start)',
  'trips.dep': 'Dep.',
  'trips.dep_end': 'Dep(end)',
  'trips.stoppage_time': 'Stoppage Time',
  'trips.trip': 'Trip',
  'trips.trip_category': 'Trip Category',
  'trips.trip_number': 'Trip No.',
  'trips.trip_name': 'Name',
  'trips.new_trip': 'New Trip',
  'trips.start_time': 'Start Time',
  'trips.end_time': 'End Time',
  'trips.interval': 'Interval(min.)',
  'trips.message': 'Message',
  'trips.alert': 'Warning Message',
  'trips.error': 'Error Message',
  'trips.trip.select_date-none': 'no-select',
  'trips.trip.select_date-all': 'All',
  'trips.trip.select_date-1': 'Weekday（mon〜fri）',
  'trips.trip.select_date-2': 'Weekday（mon〜sat）',
  'trips.trip.select_date-3': 'Saturday',
  'trips.trip.select_date-4': 'Sunday',
  'trips.trip.select_date-5': 'Public holiday',
  'trips.trip.select_date-6': 'Sun Public holiday',
  'trips.trip.select_date-7': 'Saturday',
  'trips.trip.select_date-8': 'Sat & Sun & Public holiday',
  'trips.trip.select_date-9': 'All day',
  'trips.switch.all': 'All',
  'trips.switch.start_end': 'start,end',
  'trips.input.header': 'schedule',
  'trips.input_required': 'Required input below',
  'trips.input_Departure': 'Arrival and departure time is mandatory',
  'trips.patrol_info': 'Circulation Info',
  'trips.message.require_trip_number_name': 'Trip No./Name is required',
  'trips.message.max_length_trip_number_name': 'Trip No./Name must be less than 50 digits',
  'trips.message.inconsistency_trip': 'There is an inconsistency between Route and Trip.',
  'trips.message.arr_before_previous': 'Arrival Time must be after previous Departure Time.',
  'trips.message.dep_before_arr': 'Departure Time must be after Arrival Time.',
  'trips.message.require_start_time': 'Start Time is required',
  'trips.message.is_hankaku_start_time':
    'You can only enter half-width alphanumeric characters for Start Time',
  'trips.message.require_end_time': 'End Time is required',
  'trips.message.is_hankaku_end_time':
    'You can only enter half-width alphanumeric characters for End Time',
  'trips.message.require_interval': 'Interval(min.) is required',
  'trips.message.is_hankakusuji_interval':
    'You can only enter half-width numeric characters for Interval(min.)',

  /* 車両一覧画面 */
  'vehicles.title': 'Shuttle',
  'vehicles.edit_title': 'Vehicle Details',
  'vehicles.discription1': 'You can manage and view vehicles connected to Dispatcher.',
  'vehicles.vendor': 'Vendor',
  'vehicles.numberplate': 'NumberPlate',
  'vehicles.numberplate.placeholder': 'Input whole number plate',
  'vehicles.status': 'Status',
  'vehicles.status.normal': 'Normal',
  'vehicles.status.testing': 'Testing',
  'vehicles.status.3_month_inspection': '3 month Inspection',
  'vehicles.status.vehicle_inspection': 'Vehicle Inspection',
  'vehicles.status.sold': 'Sold',
  'vehicles.status.scrapped': 'Scrapped',
  'vehicles.status.error': 'Error',
  'vehicles.status.unusual': 'Unusual',
  'vehicles.shuttle_id': 'Shuttle ID',
  'vehicles.shuttle_id.placeholder': 'Input shuttle ID',
  'vehicles.shuttle_name': 'Type',
  'vehicles.section': 'Section',
  'vehicles.alert': 'Alert',
  'vehicles.alert.yes_or_no': '',
  'vehicles.travel_distance': 'Travel Distance',
  'vehicles.size': 'Size',
  'vehicles.size.small': 'Small',
  'vehicles.size.medium': 'Medium',
  'vehicles.size.large': 'Large',
  'vehicles.size.articulated': 'Articulated',
  'vehicles.size.others': 'Others',
  'vehicles.purchase_date': 'Purchase Date',
  'vehicles.capacity': 'Capacity',
  'vehicles.wheelchair': 'Wheelchair',
  'vehicles.bicycle': 'Bicycle',
  'vehicles.vehicle_tel': 'Tel',
  'vehicles.vehicle_tel.placeholder': 'Sample:09012345678',
  'vehicles.message.require_shuttle_name': 'Type is required',
  'vehicles.message.max_length_shuttle_name': 'Type must be less than 50 digits',
  'vehicles.message.require_shuttle_id': 'Shuttle ID is required',
  'vehicles.message.max_length_shuttle_id': 'Shuttle ID must be less than 16 digits',
  'vehicles.message.is_hankaku_shuttle_id':
    'You can only enter half-width alphanumeric characters for Shuttle ID',
  'vehicles.message.require_status': 'Status is required',
  'vehicles.message.require_office': 'Office is required',
  'vehicles.message.max_length_travel_distance': 'Travel Distance must be less than 8 digits',
  'vehicles.message.millage_travel_distance':
    'Travel Distance must be a number up to 1 decimal place.',
  'vehicles.message.max_length_vendor': 'Vendor must be less than 50 digits',
  'vehicles.message.require_size': 'Size is required',
  'vehicles.message.max_length_purchase_date': 'Purchase Date must be less than 8 digits',
  'vehicles.message.is_hankaku_purchase_date':
    'You can only enter half-width alphanumeric characters for Purchase Date',
  'vehicles.message.format_purchase_date': 'Purchase Date must be in YYYYMMDD format',
  'vehicles.message.require_capacity': 'Capacity is required',
  'vehicles.message.max_length_capacity': 'Capacity must be less than 3 digits',
  'vehicles.message.is_hankaku_capacity':
    'You can only enter half-width alphanumeric characters for Capacity',
  'vehicles.message.max_length_numberplate': 'NumberPlate must be less than 16 digits',
  'vehicles.message.max_length_vehicle_tel': 'Phone number must be less than 16 digits',
  'vehicles.message.is_hankaku_vehicle_tel':
    'You can enter numbers only, hyphens or plus phone numbers',
  'vehicles.service_state.out_of_service': 'Out of service',

  /* 社員一覧画面 */
  'employees.title': 'Employee',
  'employees.edit_title': 'Edit Employee Information',
  'employees.description1': 'It will be information of employees using Dispatcher',
  'employees.description2': 'You can manage and view',
  'employees.employee_id': 'EmployeeID',
  'employees.employee_id.placeholder': 'Input Employee ID',
  'employees.name': 'Name',
  'employees.first_name': 'First Name',
  'employees.last_name': 'Last Name',
  'employees.employ_name': 'Name',
  'employees.mail_address': 'E-mail Address',
  'employees.phone_number': 'Phone Number',
  'employees.phone_number.placeholder': 'Sample:090-1234-5678',
  'employees.status': 'Status',
  'employees.status.normal': 'Normal',
  'employees.status.training': 'Training',
  'employees.status.loa': 'LOA',
  'employees.status.retired': 'Retired',
  'employees.section': 'Section',
  'employees.role': 'User Role',
  'employees.administrator': 'Administrator',
  'employees.driver': '{0}',
  'employees.supervisor': 'Supervisor',
  'employees.supervisor.subname': '(management authority)',
  'employees.engineer': 'Engineer',
  'employees.shift_manager': 'Shift Manager',
  'employees.reset_button': 'Password Reset',
  'employees.message.require_employee_id': 'EmployeeID is required',
  'employees.message.max_length_employee_id': 'EmployeeID must be less than 16 digits',
  'employees.message.is_hankaku_employee_id':
    'You can only enter half-width alphanumeric characters for EmployeeID',
  'employees.message.require_last_name': 'Last Name is required',
  'employees.message.max_length_last_name': 'Last Name must be less than 50 digits',
  'employees.message.require_first_name': 'First Name is required',
  'employees.message.max_length_first_name': 'First Name must be less than 50 digits',
  'employees.message.require_mail_address': 'E-mail Address is required',
  'employees.message.max_length_mail_address': 'E-mail Address must be less than 64 digits',
  'employees.message.no-mach': 'The format of the e-mail address is incorrect',
  'employees.message.is_hankaku_mail_address':
    'You can only enter half-width alphanumeric characters for E-mail Address',
  'employees.message.require_phone_number': 'Phone Number is required',
  'employees.message.max_length_phone_number': 'Phone Number must be less than 16 digits',
  'employees.message.is_hankakusuji_phone_number':
    'You can only enter half-width numeric characters for Phone Number',
  'employees.message.require_status': 'Status is required',
  'employees.message.reset_password': 'Reset the password for this user. Is it OK?',
  'employees.message.not_retired': 'Only employees whose status is [retired] can be deleted',
  'employees.message.require_contact': 'Contact is required',
  'employees.message.user_name': 'Resend username to registered email address',
  'employees.message.password': 'Temporary password sent to registered email address',
  'employees.message.auth.is_observer':
    'Inspection registration and alert correspondence are possible',
  'employees.message.auth.is_controller':
    'Control instructions such as in-vehicle calls, vehicle departure instructions,and display operations are possible *1 sales office, 10 people only',
  'employees.message.auth.is_service_manager':
    'Vehicle assignments and operator/office/inspection presets can be edited',
  'employees.message.auth.is_setupper':
    'Settings related to employees, vehicles, and operations are possible',
  'employees.message.auth.is_site_manager': 'Displayed to operator during route assignment',
  'employees.message.auth.can_access_personal_info':
    'Images related to personal information, various setting pages, alert images, and operator names are displayed.',

  'employees.auth.is_observer': 'Inspection/Alert response',
  'employees.auth.is_controller': 'In-car communication/Vehicle control instruction',
  'employees.auth.is_service_manager': 'Operation settings',
  'employees.auth.is_setupper': 'Initial settings',
  'employees.auth.is_site_manager': 'Vehicle equipment inspection',
  'employees.auth.can_access_personal_info': 'Personal information access',
  'employees.edit.usename': 'username',
  'employees.edit.password': 'password',
  'employees.title.usename_password': 'username/password',
  'employees.title.auth': 'Authorization type',
  'employees.title.other': 'Other information (attached files/notification alerts)',
  'employees.auth.send_username': 'Resend',
  'employees.auth.send_password': 'Reset',
  'employees.message.reload': 'Reloading because in-car/vehicle control instructions have changed',

  /* 営業所一覧画面 */
  'offices.title': 'Section',
  'offices.edit_title': 'Edit Section Information',
  'offices.button.detail': 'Detail',
  'offices.description1': 'It will be information on the sales office using Dispatcher.',
  'offices.description2': 'You can manage and view.',
  'offices.section': 'Section',
  'offices.postal_code': 'Postal Code',
  'offices.address': 'Address',
  'offices.phone_number': 'Phone Number',
  'offices.latitude': 'Latitude',
  'offices.longitude': 'Longitude',
  'offices.message.require_section': 'Section is required',
  'offices.message.max_length_section': 'Section must be less than 50 digits',
  'offices.message.is_hankakusuji_postal_code':
    'You can only enter half-width numeric characters for Postal Code',
  'offices.message.max_length_postal_code': 'Postal Code must be less than 8 digits',
  'offices.message.max_length_address': 'Address must be less than 100 digits',
  'offices.message.max_length_phone_number': 'Phone Number must be less than 16 digits',
  'offices.message.is_hankakusuji_phone_number':
    'You can only enter half-width numeric characters for Phone Number',
  'offices.message.max_length_latitude': 'Latitude must be less than 32 digits',
  'offices.message.is_hankaku_latitude':
    'You can only enter half-width numeric characters for Latitude',
  'offices.message.max_length_longitude': 'Longitude must be less than 32 digits',
  'offices.message.is_hankaku_longitude':
    'You can only enter half-width numeric characters for Longitude',
  'offices.roll_call': 'Check',

  /* 事業者情報画面 */
  'agency.title': 'Edit Company Information',
  'agency.discription': 'Please register company information that uses Dispatcher.',
  'agency.company_name': 'Company Name',
  'agency.official_name': 'Official Name',
  'agency.mail_address': 'E-mail Address',
  'agency.postal_code': 'Postal Code',
  'agency.postal_code.placeholder': 'Sample:0000000',
  'agency.address': 'Address',
  'agency.latitude': 'Latitude',
  'agency.longitude': 'Longitude',
  'agency.phone_number': 'Phone Number',
  'agency.phone_number.placeholder': 'Sample:03-1234-5678',
  'agency.representative_name': 'Representative Name',
  'agency.representative_title': 'Representative Title',
  'agency.message.require_company_name': 'Company Name is required',
  'agency.message.max_length_company_name': 'Company Name must be less than 50 digits',
  'agency.message.require_official_name': 'Official Name is required',
  'agency.message.max_length_official_name': 'Official Name must be less than 50 digits',
  'agency.message.require_mail_address': 'E-mail Address is required',
  'agency.message.max_length_mail_address': 'E-mail Address must be less than 64 digits',
  'agency.message.is_hankaku_mail_address': 'The format of the e-mail address is incorrect',
  'agency.message.no-mach': 'The format of the e-mail address is incorrect',
  'agency.message.max_length_postal_code': 'Postal Code must be less than 8 digits',
  'agency.message.is_hankakusujihyphen_postal_code':
    'You can only enter half-width numeric characters and hyphen for Postal Code',
  'agency.message.max_length_address': 'Address must be less than 100 digits',
  'agency.message.max_length_latitude': 'Latitude must be less than 32 digits',
  'agency.message.is_hankaku_latitude':
    'You can only enter half-width alphanumeric characters for Latitude',
  'agency.message.max_length_longitude': 'Longitude must be less than 32 digits',
  'agency.message.is_hankaku_longitude':
    'You can only enter half-width alphanumeric characters for Longitude',
  'agency.message.max_length_phone_number': 'Phone Number must be less than 16 digits',
  'agency.message.is_hankakusuji_phone_number':
    'You can only enter half-width numeric characters for Phone Number',
  'agency.message.require_representative_name': 'Representative Name is required',
  'agency.message.max_length_representative_name':
    'Representative Name must be less than 50 digits',
  'agency.message.require_representative_title': 'Representative Title is required',
  'agency.message.max_length_representative_title':
    'Representative Title must be less than 50 digits',

  /* 点検プリセット登録画面 */
  'check_presets.office': 'Roll Call',
  'check_presets.area': 'ODD',
  'check_presets.vehicle': 'Vehicle Devices',
  'check_presets.title': 'Check Preset Registration',
  'check_presets.description': 'Check items during operation can be registered',
  'check_presets.update_date': 'Registered Date',
  'check_presets.target': 'Category',
  'check_presets.preset_name': 'Preset Name',
  'check_presets.update_employee': 'Registered employee',
  'check_presets.goto_offices': 'Go to office settings',
  'check_presets.goto_areas': 'Go to area settings',
  'check_presets.goto_courses': 'Go to Course settings',
  'check_presets.goto_vehicles': 'Go to Vehicle settings.',
  'check_presets.message1.office': 'Check items for Roll Call can be registered.',
  'check_presets.message2.office': 'Check items for Roll Call can be set to Office Settings.',
  'check_presets.message1.area': 'Check items for ODD during operation can be registered.',
  'check_presets.message2.area': 'Check items for ODD can be set to Course or Area Settings.',
  'check_presets.message1.vehicle': 'Check items for Vehicle Devices can be registered.',
  'check_presets.message2.vehicle':
    'Check items for Vehicle Devices can be set to Vehicle Settings.',
  'check_presets.validation.max_length_50': 'Must be less than 50 characters',
  'check_presets.validation.required': 'Required',
  'check_presets.place_holder': 'Please input',

  /* バス停一覧画面 */
  'busstops.title': 'Bus Stop List',
  'busstops.edit_title': 'Bus stop details',
  'busstops.edit': 'Edit',
  'busstops.description1': 'You can edit bus stops information.',
  'busstops.description2': 'Bus stops information is reffered by route configurations.',
  'busstops.search': 'Search',
  'busstops.stop_code': 'BusStop No.',
  'busstops.stop_name': 'BusStop Name',
  'busstops.stop_add_name': 'Enter bus stop name',
  'busstops.stop_kana': 'BusStop Name(Hiragana)',
  'busstops.stop_english': 'BusStop Name(English)',
  'busstops.stop_desc': 'Additional Information',
  'busstops.stop_lat': 'Latitude',
  'busstops.stop_lon': 'Longitude',
  'busstops.zoneId': 'Fare Area ID',
  'busstops.pole_code': 'Pole No.',
  'busstops.pole_name': 'Pole Name',
  'busstops.pole_kana': 'Hiragana',
  'busstops.pole_english': 'English',
  'busstops.pole_desc2': 'Additional Information',
  'busstops.pole_lat': 'Latitude',
  'busstops.pole_lon': 'Longitude',
  'busstops.pole_lat_range': 'Latitude range',
  'busstops.pole_lon_range': 'Longitude range',
  'busstops.pole_yaw': 'Yaw angle',
  'busstops.pole_yaw_range': 'Yaw angle range',
  'busstops.pole_japanese_pronunciation': 'Japanese pronunciation',
  'busstops.pole_english_pro': 'English pronunciation',
  'busstops.button.add': 'Add',
  'busstops.button.add_pole': 'Add Pole',
  'busstops.button.del_pole': 'Delete Pole',
  'busstops.added_pole': 'Pole',
  'busstops.pole_with': 'pole with or within',
  'busstops.hexadecimal': 'Hex',
  'busstops.map': 'Map',

  'busstops.map.desc': ' location can be selected from a map and entered.',

  'busstops.message.add_pole_over_20': 'Up to 20 posts are registered.',
  'busstops.message.require_stop_code': 'Bus stop number is required',
  'busstops.message.max_length_stop_code': 'Bus stop number must be less than 50 digits',
  'busstops.message.require_stop_name': 'Bus stop name is required',
  'busstops.message.max_length_stop_name': 'Bus stop name must be less than 50 digits',
  'busstops.message.max_length_stop_kana': 'Hiragana must be less than 256 digits',
  'busstops.message.max_length_stop_english': 'English must be less than 256 digits',
  'busstops.message.is_hankaku_stop_english':
    'You can only enter half-width alphanumeric characters for English',
  'busstops.message.max_length_stop_desc':
    'Bus stop additional information be less than 256 digits or less',
  'busstops.message.is_hankaku_stop_lat':
    'You can only enter half-width alphanumeric characters for bus stop latitude',
  'busstops.message.max_length_stop_lat': 'Bus stop Please enter latitude less than 32 digits',
  'busstops.message.is_hankaku_stop_lon':
    'You can only enter half-width alphanumeric characters for bus stop latitude',
  'busstops.message.max_length_stop_lon': 'Bus stop Please enter latitude less than 32 digits',
  'busstops.message.is_hankaku_zoneId':
    'Fare area ID can only enter half-width alphanumeric characters',
  'busstops.message.max_length_zoneId': 'Fare area ID must be 8 digits or less',

  'busstops.message.require_pole_code': 'The pole number is required',
  'busstops.message.max_length_pole_code': 'Please enter the pole number with 50 digits or less',
  'busstops.message.require_pole_name': 'The pole name is required',
  'busstops.message.max_length_pole_name': 'Please input the pole name with 50 digits or less',
  'busstops.message.max_length_pole_kana': 'Hiragana must be less than 256 digits',
  'busstops.message.max_length_pole_english': 'English must be less than 256 digits',
  'busstops.message.is_hankaku_pole_english':
    'You can only enter half-width alphanumeric characters for English',
  'busstops.message.max_length_pole_desc':
    'Please input the pole additional information with 256 digits or less',
  'busstops.message.is_hankaku_pole_lat':
    'You can only enter half-width alphanumeric characters for the pole latitude',
  'busstops.message.max_length_pole_lat': 'Please input the pole latitude to 32 digits or less',
  'busstops.message.is_hankaku_pole_lon':
    'You can only enter half-width alphanumeric characters for the pole Longitude',
  'busstops.message.max_length_pole_lon': 'Please input the pole Longitude to 32 digits or less',
  'busstops.message.is_hankaku_pole_lat_range':
    'You can only enter half-width alphanumeric characters for the pole latitude range',
  'busstops.message.max_length_pole_lat_range':
    'Please input the pole latitude range to 16 digits or less',
  'busstops.message.is_hankaku_pole_lon_range':
    'You can only enter half-width alphanumeric characters for the pole Longitude range',
  'busstops.message.max_length_pole_lon_range':
    'Please input the pole Longitude range to 16 digits or less',
  'busstops.message.is_hankaku_pole_yaw':
    'You can only enter half-width alphanumeric characters for the pole yaw',
  'busstops.message.max_length_pole_yaw': 'Please input the pole yaw to 4 digits or less',
  'busstops.message.is_hankaku_pole_yaw_range':
    'You can only enter half-width alphanumeric characters for the pole yaw range',
  'busstops.message.max_length_pole_yaw_range':
    'Please input the pole yaw range to 4 digits or less',
  'busstops.message.within_range.lat': '-90 to 90',
  'busstops.message.within_range.lon': '-180 to 180',
  'busstops.message.within_range.yaw': 'between 0 and 360',
  'busstops.message.within_range.yaw_range': 'between 0 and 180',
  'busstops.message.within_range.range': 'between 0 and 30',
  'busstops.message.bad.input': ' Invalid input value',

  'busstops.cascade.deleted': 'It was deleted.',
  // eslint-disable-next-line max-len
  'busstops.message.cascade.influence':
    'There is a deleted bus stop. If you register this way, there is a possibility that the route will be affected.',

  'busstops.dropzone.title': 'Busstop Image Upload',
  'busstops.dropzone.info1': 'Image registration/replacement',
  'busstops.dropzone.info2': 'click or drag and drop',

  /* 行路一覧画面 */
  'course.title': 'Course List',
  'course.edit_title': 'Course Detail',
  'course.description1': 'Manage courses',
  'course.edit': 'Edit Course',
  'course.course_number': 'Course Number',
  'course.service_id': 'Segment',
  'course.route_id': 'Traffic classification',
  'course.operation_type': 'Operation type',
  'course.course_name': 'Course Name',
  'course.total_break_time': 'Total Rest Time(min)',
  'course.unnamed': 'Unnamed',
  'course.mission_possible': 'Use Automatic Operation',
  'course.mission_possible.yes': 'Yes',
  'course.mission_possible.no': 'No',
  'course.mission_vehicle': 'Auto departure Vehicles',

  'course.operation-type-dialog.text1': 'The added flights will be deleted',
  'course.operation-type-dialog.text2': 'because the operation type has',
  'course.operation-type-dialog.text3': 'been switched.',

  'course.button.add': 'Add',
  'course.button.course_edit': 'Edit Course',
  'course.trip.add': 'Add trip',
  'course.nothing_trip': 'There is no registered trip',
  'course.button.set_trip': 'Set trip',
  'course.button.add_edit': 'Flight can be set as a route',

  'course.trip.select_date-1': 'Weekday（mon〜fri）',
  'course.trip.select_date-2': 'Weekday（mon〜sat）',
  'course.trip.select_date-3': 'Saturday',
  'course.trip.select_date-4': 'Sunday',
  'course.trip.select_date-5': 'Public holiday',
  'course.trip.select_date-6': 'Sun Public holiday',
  'course.trip.select_date-7': 'Saturday',
  'course.trip.select_date-8': 'Sat & Sun & Public holiday',
  'course.trip.select_date-9': 'All day',

  'course.message.max_length_course_number': 'Please enter the route number with 50 digits or less',
  'course.message.require_course_number': 'Course number is mandatory',
  'course.message.require_service_id': 'Operation classification is mandatory',
  'course.message.operation_type': 'Operation type is required',
  'course.message.max_length_course_name': 'Please enter the path name with less than 128 digits',
  'course.message.require_course_name': 'Course name is required',
  'course.message.date_error': 'Please register with YYYYMMDD',

  /* 行路編集画面 */
  'courseedit.title': 'Edit Course',
  'courseedit.header': 'Course: ',
  'courseedit.sub-title': 'Lineage classification',
  'courseedit.next': 'next',
  'courseedit.arr': 'arr',
  'courseedit.dep': 'dep',
  'courseedit.trip': 'trip: ',
  'courseedit.tripcode-tripname': 'Trip No./Trip name : ',
  'courseedit.parent_route': 'Parent Route',
  'courseedit.route': 'Route',
  'courseedit.timezone': 'Time Zone',

  'courseedit.button.add_course': 'Add a Trip to this Course',
  'courseedit.button.search': 'Search',
  'courseedit.button.search_result': 'Search Result',
  'courseedit.start_time': 'Start Time',
  'courseedit.end_time': 'End Time',
  'courseedit.interval': 'Interval(min.)',
  'courseedit.expiration_date-1': 'expiration_date (start)',
  'courseedit.expiration_date-2': 'expiration_date (finish)',

  'courseedit.message.cascade': '選択したデータは不正な場合があります。',

  /* エリア設定 */
  'area.title': 'Area Setting',
  'area.edit_title': 'Area Detail',
  'area.description': 'You can set the area used by the dispatch bus.',
  'area.area_name': 'Area Name',
  'area.from_to': 'Operation Period',
  'area.area_description': 'Area Description',
  'area.from_date': 'expiration_date (start)',
  'area.to_date': 'expiration_date (finish)',
  'area.from_time': 'Start Time',
  'area.to_time': 'End Time',
  'area.service_id': 'Segment',
  'area.office_accodion': 'Target Offices',
  'area.office': 'Office',
  'area.rollCall': 'ODD',
  'area.rollCall.require_rollCall': 'Input is required',
  'area.rollCall.add_rollCall': 'Roll Call Setting',
  'area.busstops_accodion': 'List of bus stops',
  'area.busstops': 'Busstop',
  'area.busstops.require_busstops': 'Input is required',
  'area.vehicles_accodion': 'Target vehicle',
  'area.vehicles': 'Vehicle',
  'area.line_accodion': 'LINE settings',
  'area.line.radio_1': 'whole period',
  'area.line.radio_2': 'custom',
  'area.line.text_1': 'from',
  'area.line.text_2': 'to',
  'area.line.text_3': 'minutes ~',
  'area.line.text_4': 'minutes ago',
  'area.line.open': 'OPEN',
  'area.line.close': 'CLOSE',
  'area.line.start': 'Running start time',
  'area.line.hour': ':',
  'area.line.day': 'day',
  'area.abs_time.error': 'Custom date data is invalid',
  'area.hover_text.line':
    'Valid only when a scheduled flight reservation is made after a LINE account is issued.ks',

  /* インポート */
  'import.title': 'Load Import',
  'import.dropzone.title': 'GTFS',
  'import.dropzone.info2': '',

  /* アラート一覧 */
  'alert.title': 'Alert List',
  'alert.edit': 'Alert Detail',
  'alert.alert_type.placeholder': 'input alert code',
  'alert.status': 'status',
  'alert.status.not_compatible': 'New Alerts',
  'alert.status.during_complete': 'During correspondence',
  'alert.status.complete': 'Closed',
  'alert.status.not_updated': 'Not update',
  'alert.status.finish_date': 'End date and time',
  'alert.status.flag': 'Flag ON/OFF',
  'alert.alert_level': 'Alert Level',

  'alert.alert_number': 'Number',
  'alert.alert_number2': 'Number',
  'alert.alert_title': 'Title',
  'alert.alert_category_name': 'Category',
  'alert.map': 'Map',
  'alert.occuring.location': 'Alert Occuring Location',
  'alert.event_date': 'Datetime',
  'alert.update_date': 'Update Datetime',
  'alert.last_update': 'Last update',
  'alert.event_vehicle_name': 'Shuttle Type',
  'alert.all_vehicles': 'All vehicles',
  'alert.vehicle': 'Vehicle',
  'alert.owner_employee': 'Person in charge',
  'alert.owner_all_employee': 'All person in charge',
  'alert.owner_employee_name': 'Person in charge',
  'alert.alert_code': 'Alert Code',
  'alert.alert_code.placeholder': 'Input Alert Code',
  'alert.flag': 'Flag',
  'alert.alert_detail': 'Details',
  'alert.error_code': 'Error Code',
  'alert.event_course_name': 'Course',
  'alert.comment': 'Comment',
  'alert.comment_add': 'Please enter comments',
  'alert.case': 'cases',
  'alert.search': 'Search',
  'alert.comment.register_date': 'Record Date',
  'alert.comment.employee_name': 'Name',
  'alert.detailed_information': 'Detailed information',
  'alert.updatehistory': 'Comment',
  'alert.updatehistory.register_date': 'Update Date',
  'alert.updatehistory.employee_name': 'Name',
  'alert.aletlevel.information': 'INFO',
  'alert.aletlevel.warning': 'WARNING',
  'alert.ignore_analysis': 'Ignore Analytics',
  'alert.ignore_analysis.visible': 'Visible',
  'alert.ignore_analysis.hidden': 'Hidden',
  'alert.date': [
    'JAN',
    'FEB',
    'MAR',
    'ARP',
    'MAY',
    'JUN',
    'JUR',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ],
  'alert.select_date': ['week', 'month', 'last month', 'two month ago', 'Specific dates'],
  'alert.select_date_full_term': 'Full Term',

  'alert.message.input_ValidationError': 'Please update comment, status, person in charge',
  'alert.message.require_status': 'Status is mandatory',
  'alert.message.max_length_comment': 'Please enter comments with 256 digits or less',
  'alert.message.require_event_date': 'Require Event Date',
  'alert.import.message': 'Please Select Image',
  'alert.import.label': 'Image',

  /* 点呼履歴画面 */
  'roll-history.title': 'Roll history',
  'roll-history.event_date': 'Running day',
  'roll-history.office': 'Office',
  'roll-history.owner_all_office': 'All Office',
  'roll-history.owner_employee': 'Employee',
  'roll-history.owner_all_employee': 'All Employee',
  'roll-history.Vehicle': 'Vehicle',
  'roll-history.owner_all_Vehicle': 'All Vehicle',
  'roll-history.search': 'Search',

  'roll-history.running-day': 'Running day',
  'roll-history.operational-use': 'Operational Use',
  'roll-history.area-course': 'Area・Course',
  'roll-history.pre-operation-inspection': 'Pre-operation inspection',
  'roll-history.pre-operation-inspection-registrant': 'Pre-operation inspection registrant',
  'roll-history.inspection-after-operation': 'Inspection after operation',
  'roll-history.inspection-after-operation-registrant': 'Inspection after operation registrant',

  /* 点呼チェック登録画面 */
  'roll-call-check.no-data': 'Not registered',
  'roll-call-check.running-before': 'Running Before',
  'roll-call-check.running-after': 'Running After',
  'roll-call-check.header-text1': 'Be sure to register the inspection details before operating',
  'roll-call-check.header-text2':
    'If there is any abnormality, fill in the special instructions after checking.',
  'roll-call-check.roll-call': 'Roll call',
  'roll-call-check.odd': 'ODD',
  'roll-call-check.vehicle-equipment': 'Vehicle equipment',
  'roll-call-check.vehicle-equipment.message':
    '車両機器の点検は専用のアプリケーションをご使用下さい',
  'roll-call-check.update_employee': 'Update Employee',
  'roll-call-check.update-date': 'Update Date',

  'roll-call-check.remarks': '* Special notes',
  'roll-call-check.operator': 'Operator',

  /* 事故・ヒヤリハット登録画面 */
  'incident.place-of-occurrence': 'Place of Occurrence',
  'incident.weather': 'Weather',
  'incident.source': 'Source',
  'incident.incident-category': 'Incident Category',
  'incident.operator': 'Operator',
  'incident.involved-vehicle': 'Involved vehicle',
  'incident.descriptions': 'Description',
  'incident.handle': 'Response',
  'incident.driving-status': 'Driving Status',
  'incident.causes': 'Causes',
  'incident.countermeasures': 'Countermeasures',
  'incident.attachment': 'Attachment',
  'incident.incident-level': 'Incident/Near Miss Level',
  'incident.written-by': 'Written By',
  'incident.incident-type': 'Incident Type',
  'incident.involved-party': 'Involved Party',
  'incident.passenger': 'Passenger',
  'incident.attendant': 'Attendant',
  'incident.security-guard': 'Security Guard',
  'incident.injury': 'Injury',
  'incident.vehicle-conditions': 'Vehicle Conditions',
  'incident.authority-reports': 'Authority Report Status',
  'incident.authority-reports.police': 'Police',
  'incident.authority-reports.local-gov': 'Local Government',
  'incident.authority-reports.mlit': 'Ministry of Land, Infrastructure, Transport and Tourism (MLIT)',
  'incident.write-content': 'Please fill in {0}',
  'incident.download-attachment': 'Download Attachment',
  'incident.number-of-people': 'People',
  'incident.exists-yes': 'Yes',
  'incident.exists-no': 'No',

  /* アラートマップ */
  'alertmap.title': 'Alert Map',
  'alertmap.detail-title': 'Alert Detail',
  'alertmap.detail-event_date': 'Alert Detail',
  'alertmap.detail-event_date-format': 'YYYY/MM/DD HH:mm:ss',
  'alertmap.detail-alert_number': 'Alert No',
  'alertmap.detail-event_course_name': 'Course',
  'alertmap.detail-event_vehicle_name': 'Vehicle',
  'alertmap.all_vehicles': 'All vehicles',
  'alertmap.detail-alert_type': 'Alert Type',
  'alertmap.event_date-format': 'MM/DD HH:mm:ss',
  'alertmap.alert-type-incaralarm': 'Toppling over',
  'alertmap.alert-type-drivingstandup': 'Move while driving',
  'alertmap.alert-type-safety-before-auto-driving': 'Move before driving',
  'alertmap.feature-title1': 'Date',
  'alertmap.feature-title2': 'Type',
  'alertmap.low-accuracy-message': 'Low GPS Accuracy',

  'alertmap.ignore_analysis.hidden': 'hidden',
  'alertmap.ignore_analysis.cancel': 'cancel',
  'alertmap.options-week': 'Week',
  'alertmap.options-month': 'Month',
  'alertmap.options-1_month_ago': '1MonthAgo',
  'alertmap.options-2_month_ago': '2MonthAgo',
  'alertmap.options-morning': 'Morning',
  'alertmap.options-noon': 'Noon',
  'alertmap.options-night': 'Night',
  'alertmap.setting': 'Advanced Setting',
  'alertmap.frequency': 'Alert Type',
  'alertmap.times': 'times',
  'alertmap.alert_sum_times': 'Total number of alerts',
  'alertmap.alert_sum_times_caption': '* Total number',
  'alertmap.accrual_date': 'Accrual date',
  'alertmap.accrual_period': 'Accrual period',
  'alertmap.drivers': '{0}',
  'alertmap.all_drivers': 'All {0}s',
  'alertmap.options-acceleration': 'Suddenstart / rapidacceleration',
  'alertmap.options-deceleration': 'Suddenstop / rapiddeceleration',
  'alertmap.options-steering': 'Rapid turning',
  'alertmap.options-overspeed': 'Over speed',
  'alertmap.options-idling': 'Idling',
  'alertmap.options-standup_judgement_moving': 'Move while driving',
  'alertmap.options-safety-before-auto-driving': 'Move before departure',
  'alertmap.options-turnover': 'Toppling over ',
  'alertmap.options-title-maptype': 'Point / heatMap switching',
  'alertmap.options-title-dateperiod': 'Display period',
  'alertmap.options-title-timeperiod': 'Display time',
  'alertmap.options-title-alerttype': 'Alert Type',
  'alertmap.all_path': 'All_path',
  'alertmap.heat_map': 'Heat map',
  'alertmap.path': 'Path',
  'alertmap.set_open': 'Open setting',
  'alertmap.set_close': 'Close setting',
  'alertmap.check_alert': 'Please select alert content',
  'alertmap.message.error_fetch_running_data_list': 'Fetching running data failed.',
  'alertmap.message.error_fetch_alert_map_data': 'Fetching Alertmap failed.',
  'alertmap.message.error_select_trip_by_alert': 'Fetching route info failed.',
  'alertmap.message.error_get_data-1': 'There are data that have not been acquired yet.',
  'alertmap.message.error_get_data-2':
    'If it does not solve by reading again please inform the operation manager.',
  'alertmap.unknow': 'Unknow alert',
  'alertmap.alert-count-label': 'alert count label',
  'alertmap.congestion_rate': 'Congestion Rate',
  'alertmap.message.select_all': 'all',
  'alertmap.message.select_all_vehicle': 'all vehicle',
  'alertmap.message.select_all_employee': 'all employee',

  'alertmap.tab.issave': 'Save conditions',
  'alertmap.tab.export': 'EXPORT',
  'alertmap.tab.csv': 'CSV',
  'alertmap.tab.mileage_chart': 'Mileage Chart',
  'alertmap.tab.driving_time_chart': 'Driving Time Chart',
  'alertmap.tab.drive_mode': 'Drive Mode',
  'alertmap.tab.drive_mode.full': 'Auto Manual',
  'alertmap.tab.drive_mode.auto': 'Auto Drive',
  'alertmap.tab.drive_mode.manual': 'Manual Drive',
  'alertmap.tab.drive_mode.stop': 'stoped',
  'alertmap.tab.stoptime': 'Excludingstoped',
  'alertmap.tab.chart_type': 'Chart',
  'alertmap.tab.chart_type.line': 'Line Chart',
  'alertmap.tab.chart_type.bar': 'Stacked Bar Chart',
  'alertmap.tab.chart_type.simple_bar': 'Bar Chart',
  'alertmap.tab.unit': 'Unit',
  'alertmap.tab.unit.km': 'km',
  'alertmap.tab.unit.hour': 'hour',
  'alertmap.tab.unit.percent': '%',
  'alertmap.tab.vehile_name': 'Vehicle Name',
  'alertmap.tab.employee_name': 'Employee Name',
  'alertmap.tab.total_mileage': 'Total Mileage',
  'alertmap.tab.total_driving_time': 'Total Driving Time',
  'alertmap.tab.remaining_battery': 'Last Remaining Battery',
  'alertmap.tab.average_speed_chart': 'Average Speed Chart',
  'alertmap.tab.remaining_battery_chart': 'Remaining Battery Chart',
  'alertmap.tab.remaining_battery_active_period': 'Active Period',
  'alertmap.tab.alert_map': 'Alert Map',
  'alertmap.tab.driving_hash_map': 'Driving Hash Map',

  /* ダイアログ */
  'dialog.delete': 'Do you want to delete it?',
  'dialog.delete_Confirmation': 'Data can not be restored.',
  'dialog.deleted': 'It has been deleted',
  'dialog.move': 'Do you want to move?',
  'dialog.move_Confirmation': 'The data being edited will be discarded.',
  'dialog.moved': 'Moving',
  'dialog.password_reset': 'Reset',
  'dialog.password_reset_Confirmation': 'Do you really want to reset the password?',
  'dialog.password_text-1': 'When resetting, it will reset to the reset account',
  'dialog.password_text-2': 'Contact will arrive by e-mail.',
  'dialog.password_reseted': 'Reset is complete.',
  'dialog.password_reset_text-1': 'A mail was sent ',
  'dialog.password_reset_text-2': 'to the reset account',
  'dialog.update': 'Do you want to registere it?',
  'dialog.updated': 'Has registered',
  'dialog.cascade.countmessage': 'It affects {0} parent routes.',
  'dialog.cascade.messege1': 'Deleted data can not be restored',
  'dialog.cascade.messege2': 'Also, it may affect other data.',
  'dialog.notification.parmission_error':
    'Activation of notification setting failed. \n nCheck the privilege settings of the browser',

  'dialog.copy-lat-lon.message': 'Latitude and longitude copied',
  'dialog.cascade_message1': 'There is incorrect data.',
  'dialog.cascade_message2': 'Please check from the system screen.',
  'dialog.cascade_message3': 'Set up the parent route page.',
  'dialog.send_mail': 'send',
  'dialog.send_username': 'Would you like to send your username?',
  'dialog.send_username_text-1': 'We will send you an email ',
  'dialog.send_username_text-2': 'with your username',

  /* インフォメーション */
  'information.date': 'Posted date',
  'information.category': 'Category',
  'information.title': 'Title',
  'information.notice': 'Notice from BOLDLY is posted. please confirm.',
  'information.notice_date': 'Accrual date',

  /* 車輌一覧・詳細 */
  'running-vehicle.alert_confirmation-1': 'Please see the alert list',
  'running-vehicle.alert_confirmation-2': 'the alert list',
  'running-vehicle.alert_confirmation-check-1': 'Please check ',
  'running-vehicle.alert_confirmation-check-2': 'unsupported alerts',
  'running-vehicle.alert_me': 'There is an alert in handling',
  'running-vehicle.alert_me-2': 'Please check',
  'running-vehicle.alert_nothing': 'No Alerts',
  'running-vehicle.alert_nothing-2': 'Displayed when an alert occurs',
  'running-vehicle.alert_correspondence-1': 'New Alerts',
  'running-vehicle.alert_correspondence-2': 'On Going',
  'running-vehicle.alert_check_me': 'Yours',
  'running-vehicle.alert_check_other': "Others'",
  'running-vehicle.alert_flag': 'Flag ON/OFF',
  'running-vehicle.alert_correspond': 'To correspond',
  'running-vehicle.alert_correspond-text-1': 'When the correspondence is completed Please press ',
  'running-vehicle.alert_correspond-text-2': 'the response completion button',
  'running-vehicle.alert_corresponded': 'Action completed',
  'running-vehicle.alert_set_Person_in_charge': 'Set person in charge',
  'running-vehicle.alert_change_correspond': 'Responder change',
  'running-vehicle.alert_other_text-1': '【Contents】',
  'running-vehicle.alert_other_text-2': '【How to respond】',
  'running-vehicle.alert_set_all_check': 'Collective processing of similar alerts',
  'running-vehicle.alert-form-title': 'Title',
  'running-vehicle.alert-form-title-placeholder': 'Please input.',
  'running-vehicle.alert-form-category-placeholder': 'Please select.',
  'running-vehicle.alert-form-detail-placeholder': 'Please input alert details.',
  'running-vehicle.alert-form-map-select': 'Move the map to the location of the alert.',
  'running-vehicle.alert-form-map-position': 'Alert Location',
  'running-vehicle.alert-form-image': 'Image',
  'running-vehicle.alert-form-image-message1': 'Click this area',
  'running-vehicle.alert-form-image-message2': 'or drag & drop',
  'running-vehicle.alert-form-image-message3': 'an image file.',
  'running-vehicle.alert-form-image-label': 'Image',
  'running-vehicle.alert-form-video-label': 'Video',
  'running-vehicle.alert-form-map-label-notification': '(move the pin)',
  'running-vehicle.alert-form-title-require-message': 'Title is required.',
  'running-vehicle.alert-form-detail-require-message': 'Detail is required.',
  'running-vehicle.caller-button_connect': 'connecting',
  'running-vehicle.caller-button_disconnect': 'Call disconnect',
  'running-vehicle.caller-button_call': 'Talking',
  'running-vehicle.caller-button_busy': 'Busy',
  'running-vehicle.caller-button_inside_car_call': 'Call',
  'running-vehicle.caller-button_outside_car_call': 'Outside Call',
  'running-vehicle.caller-button_announcement_car_call': 'Announcement',
  'running-vehicle.call-disconnect': 'Call connection failed',

  'running-vehicle.caller-button_text-1': 'Currently, you are in a call on this screen.',
  'running-vehicle.caller-button_text-2':
    'Do you want to disconnect the call and switch to another screen?',
  'running-vehicle.caller-button_move-1': 'Stop moving',
  'running-vehicle.caller-button_move-2': 'Move to another screen',
  'running-vehicle.discomfort_index': 'Discomfort index',
  'running-vehicle.connection': 'System Connection',
  'running-vehicle.door': 'Door',
  'running-vehicle.driving-mode-1': '(Over ride)',
  'running-vehicle.driving-mode-2': 'Driving mode',
  'running-vehicle.emergency': 'Emergency',
  'running-vehicle.emergency_connection': 'Emergency notification',
  'running-vehicle.emergency_on': 'Emergency notification ON',
  'running-vehicle.emergency_off': 'Emergency notification OFF',
  'running-vehicle.emergency_sending': 'Sending',
  'running-vehicle.emergency_sending_error': 'Sending error',
  'running-vehicle.emergency_dialog-1': 'fire',
  'running-vehicle.emergency_dialog-2': 'earthquake',
  'running-vehicle.emergency_dialog-3': 'Emergency (other)',
  'running-vehicle.emergency_dialog-4': 'Vehicle breakdown',
  'running-vehicle.emergency_dialog-select': 'Please select occurrence event.',
  'running-vehicle.emergency_dialog_text-1':
    'Do you want to tell the selected event to the vehicle',
  'running-vehicle.emergency_dialog_text-2': '※ If the vehicle is running, stop.',
  'running-vehicle.emergency_dialog_text.name': 'Please provide details',
  'running-vehicle.emergency_dialog_text.placeholder': 'Please enter within 20 characters',
  'running-vehicle.emergency_dialog_text.error.isrequired': 'Message is required',
  'running-vehicle.emergency_dialog_text.error.maxlength': 'Please enter within 20 characters',
  'running-vehicle.emergency_dialog_yes': 'yes',
  'running-vehicle.emergency_dialog_no': 'no',
  'running-vehicle.emergency_fire': 'Fire',
  'running-vehicle.emergency_earthquake': 'Earthquake',
  'running-vehicle.emergency_emergency': 'Emergency',
  'running-vehicle.emergency_failure': 'Failure',
  'running-vehicle.message_level.select': 'Please Select Category',
  'running-vehicle.message_level.info': 'Info',
  'running-vehicle.message_level.warning': 'Warning',
  'running-vehicle.message_level.error': 'Error',
  'running-vehicle.engine': 'Engine',
  'running-vehicle.engine_rpm': 'Engine RPM',
  'running-vehicle.live-map_layer': 'Style Map',
  'running-vehicle.live-map_location': 'Your location',
  'running-vehicle.live-map_map': 'Map',
  'running-vehicle.multi-view_camera': 'Camera switch',
  'running-vehicle.multi-view_camera.selected': 'Selected',
  'running-vehicle.multi-view_camera.blust_view': 'Blust View',
  'running-vehicle.passengers': 'Passengers',
  'running-vehicle.speed': 'Speed',
  'running-vehicle.location_delay': 'delay',
  'running-vehicle.location_minute': 'minute',
  'running-vehicle.location_passenger': '',
  'running-vehicle.location_count': 'passengers',
  'running-vehicle.location_status': 'Driving situation',
  'running-vehicle.map_type-1': 'Satellite',
  'running-vehicle.map_type-2': 'Underground shopping center',
  'running-vehicle.map': 'Map',
  'running-vehicle.status-1': 'Normal',
  'running-vehicle.status-2': 'Alerting',
  'running-vehicle.move_to_vehicle': 'Centering',
  'running-vehicle.list_vehicle': 'Vehicle list',
  'running-vehicle.list_alert': 'Vehicle alerts',
  'running-vehicle.battery': 'Battery',
  'running-vehicle.service_mode': 'Service Mode',
  'running-vehicle.service_mode.on_demand': 'On Demand',
  'running-vehicle.service_mode.metro': 'Metro',
  'running-vehicle.service_mode.bus': 'Bus',
  'running-vehicle.vehicle_mode': 'Vehicle Mode',
  'running-vehicle.vehicle_mode.use': 'Use',
  'running-vehicle.vehicle_mode.standby': 'Standby',
  'running-vehicle.vehicle_mode.safety': 'Safety',
  'running-vehicle.car_temperature': 'Inside Temp',
  'running-vehicle.car_temperature_out': 'Outside Temp',
  'running-vehicle.coolant_temperature': 'Coolant Temperature',
  'running-vehicle.loading_quantity': 'Loading Quantity',
  'running-vehicle.hit_ratio': 'Hit Ratio',
  'running-vehicle.goto_detail': 'go to detail',
  'running-vehicle.interior': 'Interior Cameras',
  'running-vehicle.exterior': 'Exterior Cameras',
  'running-vehicle.front_camera': 'Front cameras',
  'running-vehicle.rear_camera': 'Rear camera',
  'running-vehicle.blast': 'blast',
  'running-vehicle.camera': 'camera',
  'running-vehicle.option': 'option',
  'running-vehicle.air_condition': 'Air Conditioner',
  'running-vehicle.air_condition.off': 'OFF',
  'running-vehicle.air_condition.on': 'ON',
  'running-vehicle.air_condition.auto': 'AUTO',
  'running-vehicle.running_status': 'Running Status',
  'running-vehicle.ahead_distance': 'Following Distance',
  'running-vehicle.lateral_position': 'Lateral Position',
  'running-vehicle.manual_break': 'Manual Break',
  'running-vehicle.running_position': 'Running Position',
  'running-vehicle.lap_time': 'Lap Time',
  'running-vehicle.running_route': 'Route',
  'running-vehicle.communication': 'Communication',
  'running-vehicle.power_consumption': 'Power',
  'running-vehicle.emergency_door': 'Emergency Door',
  'running-vehicle.steering_angle': 'Steering Angle',
  'running-vehicle.electricity_cost': 'Electricity Cost',
  'running-vehicle.floor': 'hierarchy',
  'running-vehicle.floor.storey': 'floor',
  'running-vehicle.floor.basement': 'basement',
  'running-vehicle.gps_sanity': 'GPS Sanity',
  'running-vehicle.data_link': 'Data Link',
  'running-vehicle.propo_mode': 'Propo Mode',
  'running-vehicle.propo_communication': 'Propo Communication',
  'running-vehicle.angular_velocity': 'Angular Velocity',
  'running-vehicle.roll': 'roll',
  'running-vehicle.pitch': 'pitch',
  'running-vehicle.heading': 'heading',
  'running-vehicle.flight_status.manual': 'Manual (except tablet mode)',
  'running-vehicle.flight_status.single_waypoint': 'Single waypoint flight',
  'running-vehicle.flight_status.planned': 'Planned flight',
  'running-vehicle.flight_status.go_home': 'Go Home',
  'running-vehicle.flight_status.emergency_landing_move': 'Emergency Landing Site Transfer',
  'running-vehicle.flight_status.emergency_landing': 'Emergency Landing',
  'running-vehicle.flight_status.pause': 'Pause',
  'running-vehicle.flight_status.emergency_mode': 'Emergency mode',
  'running-vehicle.flight_status.emergency_go_home': 'Emergency Go Home',
  'running-vehicle.flight_status.speed_command': 'Speed command',

  'running-vehicle.alert': 'Alert',
  'running-vehicle.vehicle_info': 'Vehicle Info',
  'running-vehicle.vehicle_info.auto': 'Auto',
  'running-vehicle.vehicle_info.manual': 'Manual',
  'running-vehicle.vehicle_info.battery_charge': 'Charging',
  'running-vehicle.vehicle_info.communication': 'Communication',
  'running-vehicle.vehicle_info.control': 'Auto Drive Control',
  'running-vehicle.vehicle_info.obstacle_sensor': 'Obstacle Detection',
  'running-vehicle.vehicle_info.position_sensor': 'Localization',
  'running-vehicle.vehicle_info.vehicle': 'Vehicle',
  'running-vehicle.vehicle_info.motor': 'Engine/Motor',
  'running-vehicle.vehicle_info.engine': 'Power Train',
  'running-vehicle.vehicle_info.brake': 'Brake',
  'running-vehicle.vehicle_info.energy': 'Energy',
  'running-vehicle.vehicle_info.front_door': 'Front Door',
  'running-vehicle.vehicle_info.back_door': 'Back Door',
  'running-vehicle.operation_info': 'Operation Info',
  'running-vehicle.vehicle_info.auto_driving': 'Auto Driving',
  'running-vehicle.send_notification': 'Inform',
  'running-vehicle.destination.tittle': 'Destination',
  'running-vehicle.destination.start': '▶  start',
  'running-vehicle.destination.select': 'Select from map or list',
  'running-vehicle.destination.no_support': 'This feature is not available in this environment.',
  'running-vehicle.vehicle_info.battery': 'Battery',
  'running-vehicle.vehicle_info.higher': 'Higher',
  'running-vehicle.vehicle_info.speed': 'Speed',
  'running-vehicle.address.not.get.from.vehicle':
    "Address cannot be obtained. because Vehicle Location isn't fine",
  'running-vehicle.address.get.failed': 'Failed to load. Please try again.',
  'running-vehicle.address.not.exist': 'Location for which no address information exists.',
  'running-vehicle.address.button': 'Get Address',
  'running-vehicle.text_status.normal': '[Normal]',
  'running-vehicle.text_status.abnormal': '[Abnormal]',
  'running-vehicle.text_status.emergency_stop_button': 'Emergency stop button',
  'running-vehicle.text_status.push': '[Pressed]',
  'running-vehicle.text_status.driving_assistance_device_status': 'Driving assistance device',
  'running-vehicle.text_status.vehicle_status': 'Vehicle status',

  /* リマインダーモーダルについて */
  'reminder.modal.title': 'reminder alert',
  'reminder.modal.message': 'some files are out of date. please confirm.',
  'reminder.modal.vehicle': 'vehicle',
  'reminder.modal.office': 'office',
  'reminder.modal.employee': 'employee',
  'reminder.modal.check_message': 'Do not show this message today.',

  /* infraナビゲーションについて */
  'infra.alert': 'infra alert',
  'infra.infra_tab': 'list',
  'infra.infla_alert': 'alert',
  'infra.infla_alert.option_text':
    'Once the signal failure is fixed, the response can be completed.',
  'infra.infla_alert.error_text': 'Correspondence registration failed',
  'infra.infla_alert.alert_corresponded': 'completed',
  'infra.infla_info': 'infra info',
  'infra.infla_info.close': 'close',
  'infra.infla_alert.text': ' has occurred. Please follow the steps below for troubleshooting:',
  'infra.infla_alert.alert': 'alert',

  /* Dispactherについて */
  'about.title_dispatcher': 'About Dispatcher',
  'about.product_name': 'Product Name',
  'about.version': 'Version',
  'about.auto_update': 'Auto Update',
  'about.auto_update.value': 'Enabled',
  'about.product_detail': 'Product Detail',
  'about.product_detail.value_dispatcher': 'Dispatcher Web Site',
  'about.developer': 'Developer',
  'about.developer.value': 'BOLDLY',
  'about.developer_detail': 'Developer Detail',
  'about.developer_detail.value': 'BOLDLY Web Site',
  'about.contact': 'Contact',
  'about.contact.value': 'BOLDLY Address',

  /* アカウントについて */
  'account.title_dispatcher':
    'Manage individual accounts to make Dispatcher more convenient for you.',
  'account.text_1.welcome': 'Welcome',
  'account.text_2.welcome': '',
  'account.title.other': 'Other Information',
  'account.name.placeholder': 'Alphanumeric characters, underscores, dots, 8-16 characters',
  'account.name.placeholder_2':
    'Please enter in alphanumeric characters, underscores, dots, 8-16 characters',
  'account.name.no-match':
    'This username is not available.\nPlease use alphanumeric characters, underscores, and dots within 8-16 characters.',
  'account.name.message.max_length_username': 'Please enter a username within 16 characters',
  'account.message.is_hankaku_employee_id':
    'Only alphanumeric characters are allowed for the username',
  'account.name.invalid-character': 'The username contains invalid characters',
  'account.name.invalid-length': 'Please enter a username between 8 and 16 characters',

  /* エラー画面 */
  'error-boundary.title': 'An unforeseen problem has occurred.',
  'error-boundary.desc': 'Please reload the page.',
  'error-boundary.reload': 'Reload',
  'error-boundary.link.desc1': 'If the problem persists, please ',
  'error-boundary.link.desc2': 'contact',
  'error-boundary.link.desc3': ' us.',

  /* 共通単語 */
  'common.title': 'Autonomous Vehicle Fleet Management System - Top',
  'common.vehicle_name': 'ShuttleType',
  [LANGUAGE_DRIVER_KEY]: 'Driver',
  [LANGUAGE_OPERATOR_KEY]: 'Operator',
  'common.delay_time': 'Delay Time',
  'common.on_time': 'On Time',
  'common.passenger_number': 'Passenger',
  'common.is_next_stop': 'Next Stop',
  'common.has_wheelchair': 'Wheelchair',
  'common.speed': 'Speed',
  'common.rpm': 'RPM',
  'common.occupancy': 'Occupancy',
  'common.temperature': 'Temperature',
  'common.fuel': 'Fuel',
  'common.tachograph': 'Tachograph',
  'common.passenger': 'Passenger',
  'common.fare': 'Fare',
  'common.battery': 'Battery',
  'common.tire': 'Tire',
  'common.capacity': 'Capacity',
  'common.door': 'Door',
  'common.radiator': 'Radiator',
  'common.equipment': 'Equipment',
  'common.unknow_speed': 'Unknow speed',

  'common.basiclist.items': '_START_-_END_item / _TOTAL_items',
  'common.basiclist.items-empty': '0-0 / 0items',
  'common.basiclist.previous': 'Previous',
  'common.basiclist.next': 'Next',
  'common.basiclist.no_data': 'No Result',
  'common.placeholder.search': 'Search',

  'common.loading': 'Loading',
  'common.available': 'Available',
  'common.unavailable': 'Unavailable',
  'common.normal': 'Normal',
  'common.normal2': 'Normal',
  'common.fault': 'Fault',
  'common.unknown': 'Unknown',
  'common.unacquired': 'Unacquired',
  'common.acquired': 'Acquired',
  'common.unset': 'Not Set',
  'common.not.recorded': 'Not Recorded',

  'common.there': 'there',
  'common.none': 'none',

  'common.open': 'Open',
  'common.close': 'Close',

  'common.format.ymd': 'MMM, D, YYYY, (ddd)',
  'common.format.yyyymd': 'M, D, YYYY',
  'common.format.datepicker.ym': 'MMM. yyyy',
  'common.format.ym': 'MMM. YYYY',
  'common.format.datefns.ymd': 'MMM d, yyyy, (eee)',
  'common.format.datefns.yyyymd': 'M, d, yyyy',
  'common.format.datefns.datepicker.ym': 'MMM yyyy',
  'common.format.datefns.ym': 'MMM yyyy',
  'common.sun': 'Sun',
  'common.mon': 'Mon',
  'common.tue': 'Tue',
  'common.wed': 'Wed',
  'common.thu': 'Thu',
  'common.fri': 'Fri',
  'common.sat': 'Sat',

  'common.time': 'time',
  'common.yen': 'Yen',
  'common.days': 'Days',
  'common.days.omit': 'D',
  'common.hours': 'Hours',
  'common.hours.omit': 'H',
  'common.minutes': 'Minutes',
  'common.minutes.omit': 'M',
  'common.seconds': 'Seconds',
  'common.seconds.omit': 'S',
  'common.ago': 'ago',
  'common.people': 'people',
  'common.level': 'Level',
  'common.byte_per_minute': 'Byte/min.',
  'common.km': 'km',
  'common.km_per_hour': 'km/h',
  'common.m_per_sec2': 'm/s\u00b2',
  'common.degree': '℃',
  'common.unit.rpm': 'rpm',
  'common.unit.none': 'none',
  'common.colon': ': ',
  'common.middledot': ': ',
  'common.slash': '/',
  'common.comma': ',',

  'common.save': 'Save',
  'common.yes': 'Yes',
  'common.cancel': 'Cancel',
  'common.add': 'Add',
  'common.update': 'Update',
  'common.register': 'Register',
  'common.edit': 'Edit',
  'common.delete': 'Delete',
  'common.search': 'Search',
  'common.attach': 'Attach',
  'common.all': 'All',
  'common.clear': 'Clear',
  'common.button.close': 'Close',
  'common.new': 'New',
  'common.plus': '+',
  'common.detail': 'Detail',
  'common.not-implemented': 'Not Implemented',

  'common.selected': 'selected',

  'common.storage': 'Storage',
  'common.weekday': 'WeekDay',
  'common.holiday': 'Holiday',

  'common.login': 'Login',
  'common.logout': 'Logout',
  'common.account': 'Account',

  'common.patrol': 'Circulation',
  'common.route': 'Route',
  'common.course': 'Course',

  'common.required': 'Required',
  'commmn.businessoperation': 'Business Operation',
  'commmn.fowarding': 'Fowarding',

  'common.place': 'Place',
  'common.month': '',
  'common.year': '/',

  'common.use-type.scheduled': 'Scheduled',
  'common.use-type.demand': 'Demand',
  'common.use-type': 'Use Type',

  /* 共通メッセージ */
  'common.message.network_error': 'Communication error: Please make sure the network is normal',
  'common.message.delete_confirm': 'I will delete it, is it OK?',

  'common.file.upload.standby': 'Waiting for upload',
  'common.file.upload.uploading': 'Uploading...',
  'common.file.upload.success': 'Upload successful',

  /* 合成音声 */
  'speech.voice_charactor': 'Google US English',
  'speech.running_vehicle.change_course': 'The route has been changed',
  'speech.running_vehicle.vehicle_start1': 'Soon, ',
  'speech.running_vehicle.vehicle_start2': ' will depart',
};
