import sendLog, { sendEventTracking } from '../../../../../common/utils/sendLog';

export const callStatusList = {
  awaiting: 'awaiting',
  pending: 'pending',
  talking: 'talking',
  end: 'end',
  busy: 'busy',
};

export const callPositionList = {
  inside: 'inside',
  outside: 'outside',
  announcement: 'announcement',
};

export const onClickCalling = (
  makeCallToVehicleAction: () => void,
  talkingConnection: any,
  setCallStatus: (status: string) => void,
) => {
  sendEventTracking('Click-Calling', {
    event_category: 'Dispatcher',
    event_label: 'Click-Calling',
    value: '',
  });

  if (talkingConnection?.checkConnection) {
    return;
  }
  makeCallToVehicleAction();
  setCallStatus(callStatusList.pending);
};

export const onHangup = (
  talkingConnection: any,
  hangupCallAction: (conn: any) => void,
  setCallStatus: (status: string) => void,
  setCallButtonHover: (bool: boolean) => void,
) => {
  if (talkingConnection) {
    hangupCallAction(talkingConnection);
  }
  setCallButtonHover(false);
  setCallStatus(callStatusList.end);
  setTimeout(() => {
    setCallStatus(callStatusList.awaiting);
  }, 3000);
};

export const getVehicleCall = (
  isVehicleActive: boolean,
  talkingConnection: any,
  isTalkingPhone: boolean,
  callStatus: string,
  setCallStatus: (status: string) => void,
) => {
  if (isTalkingPhone && talkingConnection && callStatus === callStatusList.awaiting) {
    talkingConnection.accept();

    talkingConnection.error = (error: any) => {
      if (isVehicleActive) {
        sendLog(
          `twilio vehicle→browser access failed ${error.code} ${error.message}', 'ERROR`,
          undefined,
          undefined,
        );
      }
    };
    setCallStatus(callStatusList.talking);
  }
};

export const changeCallStatus = (
  intervalId: NodeJS.Timer,
  talkingConnection: any,
  setCallStatus: (status: string) => void,
) => {
  if (talkingConnection) {
    const status = talkingConnection.status();
    if (status === 'open') {
      clearInterval(intervalId);
      setCallStatus(callStatusList.talking);
    }
    if (status === 'closed') {
      clearInterval(intervalId);
      setCallStatus(callStatusList.awaiting);
    }
  } else {
    clearInterval(intervalId);
    setCallStatus(callStatusList.awaiting);
  }
};
