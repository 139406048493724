import { getAPIClientAsync } from "../../common/utils/aws";
import { ResponseData } from "./apiPostResponse";

export default async function setLogs(): Promise<ResponseData> {
  const apigClient = await getAPIClientAsync();

  const params = {};
  const body = {
    dummy: 1,
  };
  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  return apigClient.setLogsPost(params, body, additionalParams);
}
