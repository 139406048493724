import moment from 'moment';

import { getAPIClientAsync } from '../../common/utils/aws';
import Res, { ResponseListData } from './apiGetResponse';

export interface GetAppendixListReq {
  appendixID?: string;
  vehicleID?: number;
  officeID?: number;
  employeeID?: number;
  stopID?: number;
  primary?: boolean;
  target?: string;
  fromReminderDate?: moment.Moment;
  toReminderDate?: moment.Moment;
  pageNum?: number;
  sortList?: string;
  limitNum?: number;
}

export interface AppensixFile {
  filename: string;
  url: string;
  expiration: number;
}

export interface AppendixItem {
  appendix_id: string;
  name: string;
  reminder_date?: string;
  reminder_contents?: string;
  primary?: boolean;
  files?: AppensixFile[];
}

export interface GetAppendixListDataItem extends AppendixItem {
  target: string;
  vehicle_id?: number;
  vehicle_name?: string;
  office_id?: number;
  office_name?: string;
  stop_id?: number;
  stop_name?: string;
  employee_last_name?: string;
  employee_first_name?: string;
  employee_id?: number;
}

export interface GetAppendixListData extends ResponseListData {
  appendices: GetAppendixListDataItem[];
}

export interface GetAppendixListRes extends Res {
  data?: GetAppendixListData;
}

/**
 * @description アラートの映像URLを取得
 * @param {String} errorCode 対象のエラーコード
 * @param {Number} eventVehicleId 対象の車両ID
 */
export default async function getAppendixList({
  appendixID,
  vehicleID,
  officeID,
  stopID,
  employeeID,
  primary,
  target,
  fromReminderDate,
  toReminderDate,
  pageNum,
  sortList,
  limitNum,
}: GetAppendixListReq): Promise<GetAppendixListRes> {
  const apigClient = await getAPIClientAsync();
  const params = {
    appendix_id: appendixID || null,
    vehicle_id: vehicleID || null,
    office_id: officeID || null,
    stop_id: stopID || null,
    employee_id: employeeID || null,
    primary: primary === undefined ? null : primary,
    target: target || null,
    from_reminder_date: fromReminderDate ? fromReminderDate.format('YYYY-MM-DD') : null,
    to_reminder_date: toReminderDate ? toReminderDate.format('YYYY-MM-DD') : null,
    sort: sortList || '',
    page: pageNum || null,
    limit: limitNum || null,
  };

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.getAppendixListGet(params, body, additionalParams);
}
