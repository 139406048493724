/**
 * @summary リマインダーデータの取得
 */

import moment from 'moment';

import getAppendixList from '../apicall/getAppendixList';
import isOK from '../apicall/isOK';
import * as actions from './actionTypes';

export function getAppendixReminder() {
  return async (dispatch: any) => {
    try {
      const result = await getAppendixList({
        toReminderDate: moment(),
        // officeID: window.loginUser.office_id || undefined,
      });
      if (isOK(result)) {
        const list = result.data?.appendices || [];
        const vehicleReminder = list.filter((item) => item.target === 'vehicle');
        const officeReminder = list.filter((item) => item.target === 'office');
        const employeeReminder = list.filter((item) => item.target === 'employee');
        dispatch({
          payload: {
            vehicleReminder,
            officeReminder,
            employeeReminder,
          },
          type: actions.GET_APPENDIX_REMINDER,
        });
      }
    } catch (ex) {
      console.log('getAppendixList error', ex);
    }
  };
}
