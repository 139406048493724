import { getCognitoClient } from '../aws';
/**
 * @description cognitoからログインユーザの情報を取得する
 */
export interface GetUserAttributesRes {
  agency_id: number;
  employee_id: number;
  office_id: number;
  name: string;
  auth: {
    is_observer: number;
    is_controller: number;
    is_service_manager: number;
    is_setupper: number;
    is_site_manager: number;
    can_access_personal_info: number;
  };
  email: string;
  is_policy_attached: boolean;
}

export const getUserAttribute = (): Promise<GetUserAttributesRes> => {
  return new Promise((resolve, reject) => {
    const lang = window.localStorage.getItem('lang');
    getCognitoClient(lang).then((cognitoClient: any) => {
      cognitoClient.getUserAttributes((err: any, result: any) => {
        if (err) {
          console.info(err.message || JSON.stringify(err));
          reject(err.message || JSON.stringify(err));
          return;
        }
        const getAttrValue = (key: any) => {
          const res = result.find((a: any) => a.getName() === key);
          if (res) {
            return res.getValue();
          }
          return null;
        };

        const roleJson = JSON.parse(getAttrValue('custom:role'));

        resolve({
          agency_id: Number(getAttrValue('custom:agency_id')),
          employee_id: Number(getAttrValue('custom:employee_id')),
          office_id: Number(getAttrValue('custom:office_id')),
          name:
            lang === 'jp'
              ? `${getAttrValue('family_name')} ${getAttrValue('given_name')}`
              : `${getAttrValue('given_name')} ${getAttrValue('family_name')}`,
          auth: roleJson,
          email: getAttrValue('email'),
          is_policy_attached: getAttrValue('custom:attached_iot_policy') === 'true',
        });
      });
    });
  });
};

export default getUserAttribute;
