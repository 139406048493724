import { TOAST_OPTION } from '../../common/constants';
import lostConnectionToast from '../../common/utils/lostConnectionToast/lostConnectionToast';
import lang from '../../lang';

export const checkStatsItem = (
  type: string,
  newStream: any,
  vehicleName: string,
  cameraDisplayName: string,
) => {
  let prevBytesReceived = 0;
  let isAlert = false;
  const toastText =
    type === 'video'
      ? lang.get('in_operation_vehicle.video.disabled')
      : lang.get('in_operation_vehicle.audio.disabled');
  const intervalId = setInterval(() => {
    newStream.getStats(function (stats: any) {
      const dataType = type === 'video' ? stats?.inboundStream?.video : stats?.inboundStream?.audio;
      const nowBytesReceived = dataType?.bytesReceived || 0;
      if (dataType === undefined) {
        clearInterval(intervalId);
        isAlert = false;
        return;
      }
      if (nowBytesReceived === prevBytesReceived && !isAlert) {
        isAlert = true;
        lostConnectionToast(
          `${vehicleName} ${type === 'video' ? cameraDisplayName : ''}: ${toastText}`,
          TOAST_OPTION,
        );
      }
      if (nowBytesReceived !== prevBytesReceived && isAlert) {
        isAlert = false;
      }
      prevBytesReceived = nowBytesReceived;
    });
  }, 5000); // 5秒毎にチェック
};
