/**
 * @description
 * Reactのエントリポイント
 *
 */
import { MediaClient } from '@tier4/webauto-media-client';
import { Amplify,Auth, AWSCloudWatchProvider, Logger as AmplifyLogger } from 'aws-amplify';
import moment from 'moment';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import getAgencyInfo from './actions/apicall/getAgencyInfo';
import isOK from './actions/apicall/isOK';
import unSubscribeAlertPush from './actions/apicall/unSubscribeAlertPush';
import { INTERNAL_MEMBER_DOMAINS } from './common/constants';
import { getCognitoClient } from './common/utils/aws';
import getUserAttribute from './common/utils/getUserAttribute';
import ErrorFallback from './pages/error';
import reducers from './reducers';
import PageRouter from './Router';

MediaClient.Config.mediaApiBaseUrl = 'https://media.drive.tier4.jp';
MediaClient.Config.mediaStreamBaseUrl = 'wss://media.drive.tier4.jp';

ReactModal.setAppElement('#mainform');

class Login {
  errorCode: any;

  loginUrl: any;

  loginUrl_en: any;

  static login(cognitoClient: any, agency: any, lang: any, params: any) {
    getUserAttribute()
      .then((result: any) => {
        const isEndUser = !INTERNAL_MEMBER_DOMAINS.some(
          (domain: any) => result.email.indexOf(domain) !== -1,
        );

        const isDeployment = params.mode === 'development' && !!result.auth.is_setupper;
        const environment = params.env ?? '';
        // ログインユーザ情報を取得
        window.loginUser = {
          ...result,
          language: lang,
          services: agency.capabilities, // 契約しているサービス
          logo: agency.logo,
          // セネック向けの暫定実装
          // is_limited: [340, 341, 342, 343, 344, 345, 346].includes(Number(result.employee_id)),
          is_limited: false,
          is_end_user: isEndUser,
          is_deployment: isDeployment,
          env: environment,
        };

        // ページ離脱時にpushを切る
        window.onbeforeunload = () => {
          // Pushのトークンを削除
          unSubscribeAlertPush()
            .then(() => console.info('submit finished'))
            .catch((e) => console.info(e));

          const startMsec = new Date();
          // 0.1秒待機
          while (new Date().valueOf() - startMsec.valueOf() < 100);

          return null; // ダイアログなし
        };

        window.gtag('set', { user_id: window.loginUser.employee_id });

        // トップページ表示

        // Reduxの設定
        const middleWares: any = [thunk, logger];
        const middleWareEnhancer = applyMiddleware(...middleWares);
        const store = createStore(reducers, compose(middleWareEnhancer));

        // Dispatcher内で予期しないエラーが発生した時にログ出力
        const onError = (error: Error, info: React.ErrorInfo) => {
          console.info('Error boundary', error.message);
          console.info('Error boundary', info.componentStack);

          // NOTE: dynamic importによるバージョン不一致の場合は強制リロード
          const regexp = /Loading [a-zA-Z]+ chunk [\d]+ failed/;
          if (regexp.test(error.message)) {
            window.location.reload();
          }

          const awsLogger = new AmplifyLogger('CloudWatchLogger', 'INFO');
          Amplify.register(awsLogger);
          awsLogger.addPluggable(new AWSCloudWatchProvider());

          awsLogger.error('Error boundary occured', error.message, info.componentStack);
          awsLogger.info(window.navigator.userAgent);
          const loggerLoginUser = Object.assign(window.loginUser);
          delete loggerLoginUser.alertCodeMessages;
          awsLogger.info(JSON.stringify(loggerLoginUser));
        };

        ReactDOM.render(
          <Provider store={store}>
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
              <PageRouter />
            </ErrorBoundary>
          </Provider>,
          document.getElementById('mainform'),
        );
      })
      .catch((err) => console.info(err));
  }

  static getUrlVars() {
    const vars: { [key: string]: any } = {};

    vars.mode = window.sessionStorage.getItem('mode') || '';
    vars.agency = window.localStorage.getItem('agency') || '';
    vars.env = window.sessionStorage.getItem('env') || '';

    return vars;
  }

  constructor() {
    this.errorCode = 'error=retry_login';
    this.loginUrl = '/login/';
    this.loginUrl_en = '/login/login_en.html';
  }

  async init() {
    const lang = window.localStorage.getItem('lang');
    const params = Login.getUrlVars();
    const agencyNameParam = 'agency' in params ? `&agency=${params.agency}` : '';
    if (lang) {
      this.setLang(lang);

      const cognitoClient = await getCognitoClient(lang);
      if (!cognitoClient) {
        // 期限切れ
        console.info('cognito user null');
        window.location.href = `${this.loginUrl}?${this.errorCode}${agencyNameParam}`;
        return;
      }
      Auth.currentCredentials()
        .then((credentials: any) => {
          window.sessionStorage.setItem('c', JSON.stringify(credentials));
          window.sessionStorage.setItem('exp', String(moment(credentials.expireTime).unix()));

          return Auth.currentAuthenticatedUser();
        })
        .then((user) => {
          window.sessionStorage.setItem('at', user.signInUserSession.accessToken.jwtToken);

          window.loginUser = {
            language: lang,
          };

          return getAgencyInfo();
        })
        .then((result) => {
          const agency = isOK(result) ? result.data : {};
          Login.login(cognitoClient, agency, lang, params);
        })
        .catch((err) => {
          console.info(err);
          Auth.signOut()
            .then(() => {
              /* empty */
            })
            .catch((errSignOut) => {
              console.info(errSignOut);
            });
        });
    } else {
      window.location.href = `${this.loginUrl}?${this.errorCode}${agencyNameParam}`;
    }
  }

  setLang(lang: any) {
    if (lang === 'en') {
      this.loginUrl = this.loginUrl_en;
    }
  }
}

const login = new Login();
login.init();
