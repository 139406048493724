/**
 * @summary twilio機能のアクション
 * @description twilio機能のアクション。
 * Twilio connectionに対する操作(acceptなど)はAction内で行う。
 * (Reducer内で操作すると、disconnection()を呼んだ際にReducerからActionが発行されてしまい例外が発生するため)
 */
import { SIP_CALL, VEHICLE_PREFIX } from '../../common/constants';
import { getTwilioAPIClientAsync } from '../../common/utils/aws';
import { sendEventTracking } from '../../common/utils/sendLog';
import lang from '../../lang';
import { callPositionList } from '../../pages/running-vehicles/RunningVehicleDetail/VehicleControls/inOutCallerButton/functions';
import { GetRunningDataDetailCourse } from '../apicall/getRunningDataDetail';
import { GetVehicleListDataItem } from '../apicall/getVehicleList';
import * as actions from './actionTypes';

// const { Device } = (window as any).Twilio;

let currentOperation: any[] = [];

/**
 * @description Twilioアクセス用のトークンを取得する
 * @returns Twilioアクセストークン
 */
async function getCapabilityToken() {
  const apigTwilioClient = await getTwilioAPIClientAsync();

  const params = {};
  const body = {
    twilioPhoneNumber: `incoming_${(window as any).loginUser.office_id}${process.env.REACT_APP_API_ENV === 'development' ? '_dev' : ''}`,
  };
  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const result = await apigTwilioClient
    .twilioRetrieveAnnouncementCapabilityTokenPost(params, body, additionalParams)
    .catch((error: any) => {
      console.info('twilioRetrieveAnnouncementCapabilityTokenPost error', error);
    });

  if (result && result.data.capabilityToken) {
    return result.data.capabilityToken;
  }

  return null;
}

/**
 * @description Twilioの初期化を行う
 */
async function twilioSetup() {
  const { Device } = window.Twilio;
  const capabilityToken = await getCapabilityToken();

  Device.setup(capabilityToken, { enableRingingState: true });
}

/**
 * @description 外部からの着信処理
 * @param {Object} dispatch Redux Action
 * @param {Object} conn Twilioコネクション
 */
function receiveIncomingCall(dispatch: any, conn: any) {
  sendEventTracking('Incoming-Call', {
    event_category: 'Dispatcher',
    event_label: 'Incoming-Call',
    value: null,
  });

  const from = conn.parameters.From.replace('client:', '');
  let vehicleID = 0;
  if (from.includes(SIP_CALL)) {
    const matchVehicleNumber = from.match(new RegExp(`${VEHICLE_PREFIX}(\\d+)`));
    const extractedValue = matchVehicleNumber && matchVehicleNumber[1];
    vehicleID = parseInt(extractedValue, 10);
  } else {
    vehicleID = parseInt(from, 10);
  }
  const foundOperation = currentOperation.find((item: any) => item.vehicle_id === vehicleID);
  if (foundOperation) {
    return dispatch({
      payload: {
        talkingConnection: conn,
      },
      type: actions.RECEIVE_INCOMING_CALL,
    });
  }

  // 該当するoperationがない場合(デマンドの予約時間外の場合)無視する
  conn.ignore();
  return null;
}

/**
 * @description 外部からの着信をキャンセルする
 * @param {Object} dispatch Redux Action
 * @param {Object} conn Twilioコネクション
 */
function cancelCall(dispatch: any, conn: any) {
  console.info('canceled');
  dispatch({
    payload: {
      talkingConnection: conn,
    },
    type: actions.CANCEL_CALL,
  });
}

/**
 * @description 外部からの着信を切断する
 * @param {Object} dispatch Redux Action
 * @param {Object} conn Twilioコネクション
 */
function disconnectCall(dispatch: any, conn: any) {
  console.info('disconnect');
  const dom = document.getElementById('mainform');
  if (dom) {
    dom.classList.toggle('dom-reflash');
  }
  dispatch({
    payload: {
      talkingConnection: conn,
      isTalkingPhone: false,
      isTalkingPC: false,
    },
    type: actions.DISCONNECT_CALL,
  });
}

/**
 * @description twilio 初期化
 */
export function initTwilio() {
  const { Device } = window.Twilio;
  twilioSetup();

  Device.connect(() => {
    // This is intentional
  });

  Device.ready(() => {
    // This is intentional
  });

  Device.error((error: any) => {
    // TODO: エラーが発生した場合(車両が待ち受けていないときとか)のUI表示必要！
    console.info('twilio device error', error);
  });

  // CapbilityTokenの有効期限切れのタイミングで呼出
  Device.offline(async (device: any) => {
    // TODO: エラーが発生した場合(車両が待ち受けていないときとか)のUI表示必要！
    console.info('twilio device offline', device);
    await twilioSetup();
  });

  return (dispatch: any) => {
    // 自身の操作による電話切断はhangupCallで処理する
    Device.disconnect(disconnectCall.bind(null, dispatch));

    // 他者の操作による電話切断(accept前)のタイミングで呼出。
    // accept後に相手が電話を切った場合はdisconnectが発生
    Device.cancel(cancelCall.bind(null, dispatch));

    // 順電時に呼び出されるタイミングで呼出
    Device.incoming(receiveIncomingCall.bind(null, dispatch));

    return {
      payload: {
        twilioReady: true,
      },
      type: actions.INIT_TWILIO,
    };
  };
}

/**
 * @description twilio受話
 * @param {Object} conn twilio connection オブジェクト
 * @param {Array} twilioCalls Twilioコネクションの配列
 */
export function acceptCall(conn: any, twilioCalls: any) {
  sendEventTracking('Accept-Call', {
    event_category: 'Dispatcher',
    event_label: 'Receiving-Call',
    value: null,
  });

  if (conn && conn.status() !== 'closed') {
    // 受話。通話開始。
    conn.accept();

    // 残りのコネクションはignoreする。(他の遠隔監視者は受話できるようにする)
    twilioCalls.forEach((item: any) => {
      if (item.conn.parameters.CallSid !== conn.parameters.CallSid) {
        item.conn.ignore();
      }
    });

    return {
      payload: {
        talkingConnection: conn,
        isTalkingPhone: true, // 相手は直電くん
        isTalkingPC: false,
      },
      type: actions.ACCEPT_CALL,
    };
  }
  return null;
}

/**
 * @description twilio通話
 * @param {Number} vehicleID 電話をかける車両ID
 */
export function makeCallToVehicle({ vehicle, course }: any) {
  const { Device } = window.Twilio;
  const vehicleID = vehicle.vehicle_id;
  sendEventTracking('Call-To-Vehicle', {
    event_category: 'Dispatcher',
    event_label: 'Call-To-Vehicle',
    value: null,
  });

  // 電話のかけ元
  const callerPhoneNumber =
    process.env.REACT_APP_API_ENV === 'production' ? '+81-50-3188-6277' : '+81-50-3187-5290';

  // 電話のかけ先 (開発環境の場合、{vehicle_id}-dev の形式で指定する)
  const callOutgoingPhoneNumber =
    process.env.REACT_APP_API_ENV === 'production'
      ? vehicleID.toString()
      : `${vehicleID.toString()}-dev`;

  const params = {
    callOutgoingPhoneNumber,
    callerPhoneNumber,
  };
  const conn = Device.connect(params);

  return async (dispatch: any) => {
    const isAudioUse = await navigator.mediaDevices
      .getUserMedia({ audio: true })
      // eslint-disable-next-line no-alert
      .catch(() =>
        window.alert(
          'マイクへのアクセスがブロックされているため通話を開始できません。\nURLバーの🔒をクリックしてマイクへのアクセスを許可して下さい。',
        ),
      );

    if (!isAudioUse) {
      dispatch({
        payload: {
          talkingConnection: conn,
          isTalkingPhone: false,
          isTalkingPC: false,
        },
        type: actions.HANGUP_CALL,
      });
      return;
    }

    conn.on('accept', () => {
      dispatch({
        payload: {
          talkingConnection: conn,
          isTalkingPhone: false,
          isTalkingPC: true, // 相手は車載PC
          vehicle,
          course,
        },
        type: actions.MAKE_CALL_TO_VEHICLE,
      });
    });
  };
}

/**
 * @description twilio通話 車内車外通話用イベント
 * @param {Number} vehicleID 電話をかける車両ID
 */
export function makeCallToInOutVehicle({
  vehicle,
  course,
  callPosition,
}: {
  vehicle: GetVehicleListDataItem;
  callPosition: string;
  course: GetRunningDataDetailCourse;
}) {
  const { Device } = window.Twilio;
  const vehicleID = vehicle.vehicle_id;
  sendEventTracking('Call-To-Vehicle', {
    event_category: 'Dispatcher',
    event_label: 'Call-To-Vehicle',
    value: null,
  });

  // 電話のかけ元
  const callerPhoneNumber =
    process.env.REACT_APP_API_ENV === 'production' ? '+81-50-3188-6277' : '+81-50-3187-5290';

  // 電話のかけ先 (開発環境の場合、{vehicle_id}-{callPosition}-dev の形式で指定する)
  const callOutgoingPhoneNumber =
    process.env.REACT_APP_API_ENV === 'production'
      ? `${vehicleID.toString()}-${callPosition}`
      : `${vehicleID.toString()}-${callPosition}-dev`;

  const params = {
    callOutgoingPhoneNumber,
    callerPhoneNumber,
  };
  const conn = Device.connect(params);
  const positionText = lang.get(`running-vehicle.caller-button_${callPosition}_car_call`);
  const cameraMicMute = callPosition === 'announcement' ? false : true;

  return async (dispatch: any) => {
    const isAudioUse = await navigator.mediaDevices
      .getUserMedia({ audio: true })
      // eslint-disable-next-line no-alert
      .catch(() =>
        window.alert(
          'マイクへのアクセスがブロックされているため通話を開始できません。\nURLバーの🔒をクリックしてマイクへのアクセスを許可して下さい。',
        ),
      );

    if (!isAudioUse) {
      dispatch({
        payload: {
          talkingConnection: conn,
          isTalkingPhone: false,
          isTalkingPC: false,
        },
        type: actions.HANGUP_CALL,
      });
      return;
    }

    conn.on('accept', () => {
      dispatch({
        payload: {
          talkingConnection: conn,
          isTalkingPhone: cameraMicMute, // 相手はSIP通話装置
          isTalkingPC: !cameraMicMute,
          vehicle: { ...vehicle, vehicle_number: positionText },
          course,
        },
        type: actions.MAKE_CALL_TO_VEHICLE,
      });
    });
  };
}

/**
 * @description twilioのCallを無視する
 * @param {Object} conn twilio connection オブジェクト
 */
export function ignoreCall(conn: any) {
  sendEventTracking('Ignore-Call', {
    event_category: 'Dispatcher',
    event_label: 'Ignore-Call',
    value: null,
  });

  if (conn && conn.status() !== 'closed') {
    conn.ignore();
    return {
      payload: {
        talkingConnection: conn,
        isTalkingPhone: false,
        isTalkingPC: false,
      },
      type: actions.IGNORE_CALL,
    };
  }
  return null;
}

/**
 * @description twilio終話
 * @param {Object} conn 終話させるTwilioコネクション
 */
export function hangupCall(conn: any) {
  sendEventTracking('Hangup-Call', {
    event_category: 'Dispatcher',
    event_label: 'Hangup-Call',
    value: null,
  });

  if (conn && conn.status() !== 'closed') {
    conn.disconnect();
  }

  return {
    payload: {
      talkingConnection: conn,
      isTalkingPhone: false,
      isTalkingPC: false,
    },
    type: actions.HANGUP_CALL,
  };
}

/**
 * @description かかってきた通話を(acceptする前に)拒否
 * @param {Object} conn 拒否するTwilioコネクション
 */
export function rejectCall(conn: any) {
  if (conn && conn.status() !== 'closed') {
    conn.reject();

    return {
      payload: {
        talkingConnection: conn,
        isTalkingPhone: false,
        isTalkingPC: false,
      },
      type: actions.REJECT_CALL,
    };
  }
  return null;
}

/**
 * @description 当日の運行情報を取得する（どの車両からかかってきたがに使う）
 * @param {Array} operation 運行情報
 */
export function setCurrentOperation(operation: any[]) {
  currentOperation = operation;
  return {
    payload: '',
    type: '',
  };
}
