import React, { Component } from 'react';

import getVideoStreamEndpoint from '../../actions/apicall/getVideoStreamEndpoint';
import isOK from '../../actions/apicall/isOK';
import { TOAST_OPTION } from '../../common/constants';
import lostConnectionToast from '../../common/utils/lostConnectionToast/lostConnectionToast';
import lang from '../../lang';
import makeCancelable from './makeCancelable';

const RETRY_COUNT_MAX = 3;
const RETRY_INTERVAL_MSEC = 3000;
const LONG_RETRY_INTERVAL_MSEC = 60000;

interface Props {
  children: any[];
  operation: any[];
}
interface State {
  session: any;
  videoStreamEndpoint: { [key: string]: any };
  retryCount: { [key: string]: any };
}
/**
 * @description ストリーム再生用のトークンの取得＆更新処理
 * @param {*} children トークンを渡すオブジェクト
 * @param {Array} operation 当日の運行情報一覧
 */
class LiveViewSession extends Component<Props, State> {
  public static defaultProps = {
    children: null,
    operation: null,
  };

  cancelableCall: any;

  retryTimer: any;

  static makeUniqueOperation(operation: any) {
    const vehicleIDList: any = [];

    return operation.filter((item: any) => {
      const exists = vehicleIDList.indexOf(item.vehicle_id) >= 0;
      if (exists) {
        return false;
      }
      vehicleIDList.push(item.vehicle_id);
      return true;
    });
  }

  constructor(props: any) {
    super(props);

    this.state = {
      session: null,
      videoStreamEndpoint: {}, // vehicleIDをキーに持つMap
      retryCount: {}, // vehicleIDをキーに持つMap
    };

    this.retryTimer = {}; // vehicleIDをキーに持つMap

    this.retryCreateSession = this.retryCreateSession.bind(this);

    this.cancelableCall = {};
  }

  async componentDidMount() {
    const { operation } = this.props;
    try {
      (window as any).Flashphoner.init({
        flashMediaProviderSwfLocation: '../media-provider.swf',
        receiverLocation: '../dependencies/websocket-player/WSReceiver2.js',
        decoderLocation: '../dependencies/websocket-player/video-worker2.js',
        preferredMediaProvider: 'WebRTC',
      });
    } catch (e) {
      window.alert("Your browser doesn't support WebRTC technology.");
    }

    if (operation) {
      const filteredOperation = LiveViewSession.makeUniqueOperation(operation);
      filteredOperation.forEach((operationItem: any) => {
        this.fetchVideoStreamEndpoint(operationItem.vehicle_id);
      });
    }
  }

  componentDidUpdate(prevProps: any) {
    const { operation } = this.props;

    if (prevProps.operation && prevProps.operation.length === 0 && operation.length > 0) {
      // 最初にoperationが取得できたときのみフェッチする
      const filteredOperation = LiveViewSession.makeUniqueOperation(operation);
      filteredOperation.forEach((operationItem: any) => {
        this.fetchVideoStreamEndpoint(operationItem.vehicle_id);
      });
    }
  }

  componentWillUnmount() {
    const sessionStatus = window.Flashphoner.constants.SESSION_STATUS;
    const session = (window as any).Flashphoner.getSessions();

    session.forEach((sessionItem: any) => {
      sessionItem.on(sessionStatus.ESTABLISHED, () => {
        /* empty */
      });
      sessionItem.on(sessionStatus.DISCONNECTED, () => {
        /* empty */
      });
      sessionItem.on(sessionStatus.FAILED, () => {
        /* empty */
      });
      sessionItem.disconnect();
      // disconnect後にstatus()を呼んでもESTABLISHEDが返ってきてしまうので
      // 独自に接続断かどうかを保持する
      // eslint-disable-next-line no-param-reassign
      sessionItem.isDisconnected = true;
    });

    if (this.retryTimer) {
      Object.keys(this.retryTimer).forEach((id) => {
        clearTimeout(this.retryTimer[id]);
      });
    }

    Object.keys(this.cancelableCall).forEach((key) => {
      if (this.cancelableCall[key]) {
        this.cancelableCall[key].cancel();
      }
    });
  }

  setSession(vehicleID: any, newSession: any) {
    const { session } = this.state;
    const currentVideoSession = Object.assign({}, session);
    currentVideoSession[vehicleID] = newSession;
    this.setState({
      session: currentVideoSession,
    });
  }

  retryCreateSession(vehicleID: any) {
    const { retryCount, videoStreamEndpoint } = this.state;
    const copiedCount = Object.assign({}, retryCount, {
      [vehicleID]: retryCount[vehicleID] + 1,
    });
    this.setState({
      retryCount: copiedCount,
    });
    this.createVideoSession(videoStreamEndpoint[vehicleID], vehicleID);
  }

  async fetchVideoStreamEndpoint(vehicleID: any) {
    const { videoStreamEndpoint } = this.state;
    this.cancelableCall[vehicleID] = makeCancelable(
      getVideoStreamEndpoint({
        vehicleID,
      }),
    );
    // }), vehicleID);
    const result = await this.cancelableCall[vehicleID].promise.catch((error: any) =>
      console.info('error', error),
    );
    this.cancelableCall[vehicleID] = null;

    if (isOK(result)) {
      const copiedObject = Object.assign({}, videoStreamEndpoint);
      copiedObject[vehicleID] = result.data.endpoint;
      this.setState({
        videoStreamEndpoint: copiedObject,
      });

      this.createVideoSession(result.data.endpoint, vehicleID);
    }
  }

  createVideoSession(endpoint: any, vehicleID: any) {
    const sessionStatus = window.Flashphoner.constants.SESSION_STATUS;
    const url = `wss://${endpoint}`;
    const accessToken = window.sessionStorage.getItem('at');

    (window as any).Flashphoner.createSession({
      urlServer: url,
      appKey: 'surveillanceService',
      custom: { token: accessToken, vehicle_id: vehicleID },
    })
      .on(sessionStatus.ESTABLISHED, (newSession: any) => {
        const { retryCount } = this.state;

        console.info('Session ESTABLISHED');
        this.setSession(vehicleID, newSession);
        const copiedCount = Object.assign({}, retryCount, {
          [vehicleID]: 0,
        });
        this.setState({
          retryCount: copiedCount,
        });
      })
      .on(sessionStatus.DISCONNECTED, () => {
        lostConnectionToast(lang.get('in_operation_vehicle.server.disabled'), TOAST_OPTION);
        console.info('Session DISCONNECTED');
        this.setSession(vehicleID, null);
      })
      .on(sessionStatus.FAILED, () => {
        const { retryCount } = this.state;
        if (retryCount[vehicleID] === 0) {
          lostConnectionToast(lang.get('in_operation_vehicle.server.disabled'), TOAST_OPTION);
        }

        console.info('Session FAILED');
        this.setSession(vehicleID, null);
        if (retryCount[vehicleID] < RETRY_COUNT_MAX) {
          console.info('retry create session: ', retryCount[vehicleID]);
          this.retryTimer[vehicleID] = setTimeout(
            () => this.retryCreateSession(vehicleID),
            RETRY_INTERVAL_MSEC * retryCount[vehicleID],
          );
        } else {
          console.info('retry create session: ', retryCount[vehicleID]);
          this.retryTimer[vehicleID] = setTimeout(
            () => this.retryCreateSession(vehicleID),
            LONG_RETRY_INTERVAL_MSEC,
          );
        }
      });
  }

  render() {
    const { children } = this.props;
    const { session } = this.state;
    const childrenWithLiveViewSession = React.Children.map(children, (child: any) =>
      React.cloneElement(child, {
        liveViewSession: session,
      }),
    );

    return (
      <div
        className="vehicle-list-container "
        style={{ display: 'flex', width: '100%', height: '100%' }}
        data-test={'live_view_session'}
      >
        {childrenWithLiveViewSession}
      </div>
    );
  }
}

export default LiveViewSession;
