import React from 'react';

import lang from '../../../lang';

interface Props {
  busData: any;
  hangupCall: (conn: any) => void;
  rejectCall: (conn: any) => void;
  isTalking: boolean;
  conn: any;
  accept: (conn: any, busData: any) => void;
  selectedVehicle?: any;
  navigate: any;
}
/**
 * @description Twilio着信ダイアログのコンテナ
 * @param {Object}   busData 車両情報
 * @param {Object}   conn Twilio Connectionオブジェクト
 * @param {Function}   hangupCall 電話に切る
 * @param {Function}   rejectCall 電話を拒否する
 * @param {Function}   accept 電話に出る
 * @param {boolean}   isTalking 通話中フラグ
 * @param {Object}   selectedVehicle 画面で選択中の車両（運行中詳細のみ）
 * @returns react object
 */
const CallDialogPanel: React.FC<Props> = (props) => {
  const { busData, conn, hangupCall, rejectCall, accept, isTalking, selectedVehicle, navigate } =
    props;

  let rightButton: any = null;
  if (isTalking) {
    rightButton =
      selectedVehicle &&
      selectedVehicle.runningDataDetail.vehicle.vehicle_id === busData.vehicleID ? (
          ''
        ) : (
          <a
            role="button"
            onClick={() => {
            // 詳細へ飛ばす
              navigate('/pages/running-vehicles', {
                state: {
                  isCallReceived: true,
                  vehicleName: busData.vehicleName,
                  vehicleDetail: busData.vehicleDetail,
                  vehicleID: busData.vehicleID,
                },
              });
            }}
            className="button-goto-detail"
            tabIndex={0}
          >
            {lang.get('in_operation_vehicle.goto_detail')}
          </a>
        );
  } else {
    rightButton = (
      // 通話を受ける
      <a
        role="button"
        onClick={() => accept(conn, busData)}
        className="button-accept"
        tabIndex={0}
      ></a>
    );
  }

  return (
    <div key={`call_${conn.parameters.CallSid}`} className="no-margin" style={{ height: '155px' }}>
      <header className="header">
        <div className="vehicle-name">{`${busData.vehicleNumber} ${busData.vehicleName}`}</div>
      </header>
      <div className="form-group body">
        <a
          role="search"
          onClick={() => {
            if (isTalking) {
              // 通話を切る
              hangupCall(conn);
            } else {
              // 通話をaccpetせずに拒否する
              rejectCall(conn);
            }
          }}
          className="button-hangup"
        ></a>
        {rightButton}
      </div>
    </div>
  );
};
export default CallDialogPanel;

// calldialogpanel.proptypes = {
//   busdata: proptypes.object.isrequired,
//   hangupcall: proptypes.func.isrequired,
//   rejectcall: proptypes.func.isrequired,
//   istalking: proptypes.bool.isrequired,
//   conn: proptypes.object.isrequired,
//   accept: proptypes.func.isrequired,
//   history: proptypes.object.isrequired,
//   selectedvehicle: proptypes.object,
// };
CallDialogPanel.defaultProps = {
  selectedVehicle: null,
  navigate: () => {
    /* empty */
  },
};
