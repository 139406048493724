import _ from 'lodash';

import { GetLoginMediaListDataItem } from '../../../actions/apicall/getLoginMediaList';
import * as types from '../../../actions/whitelabel/actionTypes';
import { companyDefault } from '../../../contents/css/constants/primitiveColor';
import { WhiteLabelReducerState } from './WhiteLabelReducer';

export function updateWhiteLabel(whitelabel: WhiteLabelReducerState) {
  return {
    payload: whitelabel,
    type: types.UPDATE_WHITE_LABEL,
  };
}

const DEFAULT_BACKGROUND_IMG = '/static/set/img/login/default.jpg';
const DEFAULT_SERVICE_LOGO = '/static/set/img/login/logo_Dispatcher.svg';
const DEFAULT_COMPANY_LOGO = '/static/set/img/common/logo.svg';

export function makeNewWhiteLabel(
  prewhitelabel: WhiteLabelReducerState,
  mediaResouces: GetLoginMediaListDataItem[],
) {
  const newWhiteLabel = _.cloneDeep(prewhitelabel);
  const LABEL = {
    COLOR: 'color',
    LOGO: 'logo',
    LOGIN: 'login',
    SERVICE_LOGO: 'serviceLogo',
  };

  const updateProperty = (label: string, property: string) => {
    const resource = mediaResouces.find((media) => media.informations.label === label);
    if (resource && resource.informations[property]) {
      (newWhiteLabel as unknown as { [key: string]: string })[property] =
        resource.informations[property];
    }
  };

  // デフォルトの設定
  newWhiteLabel.headerColor = companyDefault.header;
  newWhiteLabel.iconColor = companyDefault.icon;
  newWhiteLabel.logoColor = companyDefault.logo;
  newWhiteLabel.menuBackgroundColor = companyDefault.menuBg;
  newWhiteLabel.menuBackgroundActiveColor = companyDefault.menuBgActive;
  newWhiteLabel.companyLogoUrl = DEFAULT_COMPANY_LOGO;
  newWhiteLabel.serviceLogoUrl = DEFAULT_SERVICE_LOGO;
  newWhiteLabel.backgroundImageUrl = DEFAULT_BACKGROUND_IMG;

  for (const label of Object.values(LABEL)) {
    switch (label) {
      case LABEL.COLOR: // カンパニーカラー
        updateProperty(label, 'headerColor');
        updateProperty(label, 'menuBackgroundColor');
        updateProperty(label, 'menuBackgroundActiveColor');
        updateProperty(label, 'iconColor');
        updateProperty(label, 'logoColor');
        break;
      case LABEL.LOGO: // カンパニーロゴ
        const logoResouce = mediaResouces.find((media) => media.informations.label === label);
        if (logoResouce && logoResouce.files.length > 0) {
          newWhiteLabel.companyLogoUrl = logoResouce.files[0].url;
          newWhiteLabel.companyLogoExpiration = logoResouce.files[0].expiration;
        }
        break;
      case LABEL.LOGIN: // 背景画像
        const backgroundResource = mediaResouces.find(
          (media) => media.informations.label === label,
        );
        if (backgroundResource && backgroundResource.files.length > 0) {
          newWhiteLabel.backgroundImageUrl = backgroundResource.files[0].url;
          newWhiteLabel.backgroundImageExpiration = backgroundResource.files[0].expiration;
        }
        break;
      case LABEL.SERVICE_LOGO: // サービスロゴ
        const serviceLogoResource = mediaResouces.find(
          (media) => media.informations.label === label,
        );
        if (serviceLogoResource && serviceLogoResource.files.length > 0) {
          newWhiteLabel.serviceLogoUrl = serviceLogoResource.files[0].url;
          newWhiteLabel.serviceLogoExpiration = serviceLogoResource.files[0].expiration;
        }
        break;
      default:
        break;
    }
  }
  return newWhiteLabel;
}
