import { getAPIClientAsync } from '../../common/utils/aws';
import Res, { CommonDataRes, ResponseListData } from './apiGetResponse';
import { AppendixItem } from './getAppendixList';

export interface GetVehicleListReq {
  sortList?: string;
  pageNum?: number;
  limitNum?: number;
  useType?: number;
  configurable?: number;
  officeId?: number;
  appendix?: boolean;
}
export interface CapabilitiesDataItem {
  behavior?: {
    color?: {
      range: {
        lte?: number;
        gt?: number;
      };
      value?: string;
    }[];
  };
  key: string;
  priority?: number;
  position?: string;
}

export interface GetVehicleListDataItem {
  vehicle_id: number;
  vehicle_name: string;
  vehicle_number: string;
  office_id: number;
  office_name: string;
  preset_id: string;
  status: number;
  has_alert?: number;
  mileage?: number;
  vehicle_maker?: string;
  vehicle_type?: number;
  use_type?: number;
  vehicle_purchase_date?: string;
  capacity: number;
  wheelchair_accesible?: number;
  bikes_allowed?: number;
  vehicle_tel?: string;
  number_plate?: string;
  vehicle_type_id?: number;
  cameras?: {
    camera_id: string;
    camera_name: string;
    position: number;
  }[];
  appendices?: AppendixItem[];
  capabilities?: {
    alert: string;
    data: CapabilitiesDataItem[];
    function: string[];
  };
}

export interface GetVehicleListData extends ResponseListData {
  vehicles: GetVehicleListDataItem[];
}

export interface GetVehicleListRes extends Res {
  data?: GetVehicleListData & CommonDataRes;
}

/**
 * @description 車両一覧を取得する
 * @param {String} sortList ソート順
 * @param {Number} pageNum ページ番号
 * @param {Number} limitNum １ページ上限
 * @param {Number} useType 使用用途
 * 0: 路線
 * 1: 予約
 * @param {Number} configurable 車載の設定ファイルが更新できる車両のみに制限するかどうか
 * 1: 更新できる車両のみ
 * それ以外: 全車両 (指定しないのと同じ)
 * @param {*} officeId 営業所ID
 */
export default async function getVehicleList({
  sortList,
  pageNum,
  limitNum,
  useType,
  configurable,
  officeId,
  appendix,
}: GetVehicleListReq): Promise<GetVehicleListRes> {
  const apigClient = await getAPIClientAsync();
  const params = {
    office_name: null,
    vehicle_number: null,
    vehicle_maker: null,
    vehicle_id: null,
    status: null,
    vehicle_type: null,
    vehicle_name: null,
    sort: sortList || 'vehicle_name',
    page: pageNum || null,
    limit: limitNum || null,
    use_type: useType === undefined ? null : useType,
    configurable: configurable || null,
    office_id: officeId || null,
    appendix: appendix || false,
  };

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.getVehicleListGet(params, body, additionalParams);
}
