import { combineReducers } from 'redux';

import alertReducer, { AlertReducerState } from './alert/alertReducer';
import appendixReminderReducer, {
  AppendixReminderReducerState,
} from './appendixReminder/appendixReminderReducer';
import geolocationReducer, { GeoLocationReducerState } from './geolocation/geolocationReducer';
import infraAlertReducer, { InfraAlertReducerState } from './infra/infraAlertReducer';
import intersectionReducer, { IntersectionReducerState } from './infra/intersectionReducer';
import mqttReducer, { MqttReducerState } from './infra/mqttReducer';
import infraActiveAlertCountReducer, {
  InfraAlertCountReducerState,
} from './infraAlert/InfraActiveAlertCountReducer';
import agencyDefinitionReducer, {
  AgencyDefinitionReducerState,
} from './message/agencyDefinitionReducer';
import notificationReducer, { NotificationReducerState } from './notification/notificationReducer';
import reserveScheduledReducer, {
  ReserveScheduleReducerState,
} from './reserveschedule/reservescheduleReducer';
import runningReducer, { RunningReducerState } from './running/runningReducer';
import websocketReducer, { WebSocketReducerState } from './running/websocketReducer';
import twilioReducer, { TwillioReducerState } from './twilio/TwilioReducer';
// ドメインモデルに関するReducer
// Viewに関するReducer
import commonView, { CommonViewReducerState } from './view/common/CommonViewReducer';
import whiteLabelReducer, { WhiteLabelReducerState } from './view/whitelabel/WhiteLabelReducer';

export interface ReducerState {
  commonView: CommonViewReducerState;
  twilioReducer: TwillioReducerState;
  runningReducer: RunningReducerState;
  websocketReducer: WebSocketReducerState;
  notificationReducer: NotificationReducerState;
  agencyDefinitionReducer: AgencyDefinitionReducerState;
  alertReducer: AlertReducerState;
  geolocationReducer: GeoLocationReducerState;
  reserveScheduledReducer: ReserveScheduleReducerState;
  appendixReminderReducer: AppendixReminderReducerState;
  intersectionReducer: IntersectionReducerState;
  infraAlertReducer: InfraAlertReducerState;
  mqttReducer: MqttReducerState;
  infraActiveAlertCountReducer: InfraAlertCountReducerState;
  whiteLabelReducer: WhiteLabelReducerState;
}

// 現状、画面ごとにReducerを分割しているが、ドメインモデルごとに分けた
// 方がいいかもしれない (Reducerを共有するケースがあるため)
const rootReducer = combineReducers<ReducerState>({
  // ドメインモデルに関するReducer

  // Viewに関するReducer
  commonView,
  twilioReducer,
  runningReducer,
  websocketReducer,
  notificationReducer,
  agencyDefinitionReducer,
  alertReducer,
  geolocationReducer,
  reserveScheduledReducer,
  appendixReminderReducer,
  intersectionReducer,
  infraAlertReducer,
  mqttReducer,
  infraActiveAlertCountReducer,
  whiteLabelReducer,
});

export default rootReducer;
