import moment from 'moment';

import getToday from '../getToday';

// HH:mmの形式の文字列をパースする
// {
//     moment: momentオブジェクト (24:00を超える文字列は00:00-23:59の範囲に入れ、1日プラスする)
//     isNextDay: 翌日か否かを示すboolean
// }
/**
 * @description
 * HH:mmの形式の文字列をパースする (24:00を超える文字列も受け付ける)
 * null を渡した場合は04:00とみなして処理を行う。
 * 出力
 * {
 *   moment: momentオブジェクト (24:00を超える文字列は00:00-23:59の範囲に入れ、1日プラスする)
 *   isNextDay: 翌日か否かを示すboolean
 * }
 */
const ParseTime = (time: any) => {
  let isNextDay = false;
  let momentObj: any = null;

  let HHmm;

  if (time) {
    HHmm = time.split(':');
  } else {
    return {
      moment: getToday().set({
        hour: 4,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      isNextDay: false,
    };
  }

  if (HHmm.length === 2) {
    const HH = Number(HHmm[0]);
    const mm = Number(HHmm[1]);
    if (!Number.isNaN(HH) && !Number.isNaN(mm)) {
      if (HH < 24) {
        momentObj = getToday().set({
          hour: HH,
          minute: mm,
          second: 0,
          millisecond: 0,
        });
      } else {
        // 24:00を超えるケース
        isNextDay = true;
        momentObj = getToday().set({
          hour: HH - 24,
          minute: mm,
          second: 0,
          millisecond: 0,
        });
        momentObj = momentObj.add('days', 1);
      }
    } else {
      momentObj = getToday().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }
  } else {
    momentObj = getToday().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  return {
    moment: momentObj,
    isNextDay,
  };
};

/**
 * 業務時間ベースの時分文字列を現実時間のmomentへ変換する
 * @param hhmm "HH:MM"の文字列
 * @returns moment
 */
export const convertToRealMomentFromBusinessHHmm = (hhmm: string) => {
  const time = hhmm.split(':');
  const hh = Number(time[0]);
  const mm = Number(time[1]);

  // 時刻は業務的に4時間後ろにずらされているため、それを加味する
  const FOUR_HOUR = 4;
  const convertRealHH = hh - FOUR_HOUR < 0 ? '' : hh - FOUR_HOUR;
  const hour = `${`00${convertRealHH}`.slice(-2)}`;
  const minutes = `${`00${mm}`.slice(-2)}`;
  return moment(`${hour}:${minutes}`, 'HH:mm');
};

export default ParseTime;
