import styled from 'styled-components';

import styleConstants from '../../contents/css/constants/styleConstants';
import CustomMedia from '../running-vehicles/CustomMedia';

interface LiveViewContainerProps {
  $useTopPage?: boolean;
}
export const LiveViewContainer = styled.div<LiveViewContainerProps>`
  ${({ $useTopPage }: CameraContainerProps) =>
    $useTopPage
      ? `
      position: relative;//一覧画面
      width: 255px;//一覧画面
    `
      : `
      position: absolute; //詳細画面
      width: 100%;//詳細画面
    `}
  ${CustomMedia.lessThan('pcSmall')`
    ${({ $useTopPage }: any) =>
    $useTopPage
      ? `
    width: 200px;//一覧画面
    `
      : ''}
  `}

  height: 100%;
  position: relative;
`;

interface CameraContainerProps {
  $useTopPage?: boolean;
}
export const Div_CameraContainer = styled.div<CameraContainerProps>`
  ${({ $useTopPage }: CameraContainerProps) =>
    $useTopPage
      ? `
      position: relative;//一覧画面
      width: 255px;//一覧画面
    `
      : `
      position: absolute; //詳細画面
      width: 100%;//詳細画面
    `}

  ${CustomMedia.lessThan('pcSmall')`
    ${({ $useTopPage }: any) =>
    $useTopPage
      ? `
    width: 200px;//一覧画面
    `
      : ''}
  `}

  height: 100%;
  margin-bottom: -10px;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:before {
    content: '';
    width: 100%;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
  }

  video {
    width: 100%;
  }
`;
export const VideoCaption = styled.div`
  position: absolute;
  top: -30px;
  ${Div_CameraContainer}:hover & {
    top: 0;
  }
  left: 0;
  z-index: 2;
  width: 100%;
  height: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: ${styleConstants.text.light.secondary};
  padding: 5px;
  font-size: 14px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`;
interface LoadingBoxProps {
  $showLoadingBox: boolean;
}

export const Div_LoadingBox = styled.div<LoadingBoxProps>`
  background-color: ${styleConstants.background.light.disabled};
  width: 100%;
  height: 100%;
  position: relative;

  display: ${({ $showLoadingBox }: any) => !$showLoadingBox && 'none'};
`;

export const LoaderInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;

export const FullScreenButton = styled.a`
  text-align: center;
  color: ${styleConstants.text.light.secondary};
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  cursor: pointer;
  display: block;
  background-color: ${styleConstants.background.light.default};
  opacity: 0.78;
  border-radius: 10px;

  &:active {
    color: ${styleConstants.text.light.disabled};
  }

  &.refresh {
    left: 10px;
  }

  &.fullscreen {
    right: 10px;
  }

  &.fullscreen i {
    font-size: 20px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    opacity: 1;
    color: ${styleConstants.text.light.secondary};
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6));
  }
`;
