/**
 * @description
 * Reactのルーティング処理
 *
 */
import { createBrowserHistory as createHistory } from 'history';
import React from 'react';
import MediaQuery from 'react-responsive';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import StackTrace from 'stacktrace-js';

import LocationListener from '../common/components/locationListener';
import getAuthority from '../common/utils/getAuthority';
import sendLog from '../common/utils/sendLog';
import lang from '../lang';

type ComponentProps = DispatchToProps & StateToProps;

class PageRouter extends React.Component<ComponentProps> {
  isRender: boolean;

  isSetAgency: boolean;

  constructor(props: any) {
    super(props);
    // タイトル変更
    let language = 'jp';

    // セッションパラメータから判断
    // if (window.loginUser && 'language' in window.loginUser) {
    if ((window as any).loginUser && 'language' in (window as any).loginUser) {
      // ({ language } = window.loginUser);
      ({ language } = (window as any).loginUser);
    }
    lang.setEnv(language);

    let errorMessage = '';
    // React内でエラーが発生した場合はここのアクションが発火する
    const callback = (stackframes: any) => {
      const stringifiedStack = stackframes.map((sf: any) => sf.toString()).join('\n');
      sendLog(errorMessage, 'ERROR', stringifiedStack);
    };

    const errback = (err: any) => {
      console.info(err.message);
    };

    window.onerror = (msg, file, line, col, error) => {
      errorMessage = `An error occurred at line ${line} of ${file}: ${msg}`;
      if (error) {
        StackTrace.fromError(error).then(callback).catch(errback);
      }
    };

    // eslint-disable-next-line no-undef
    if (!matchMedia('(max-width: 767px)').matches) {
      // ウィンドウサイズが767px以上のとき
      // Twilioの初期化はPCだけ
      const { isTwilioUse } = getAuthority('common');
      if (isTwilioUse) {
        props.initTwilioAction();
      }

      // Push通知もPCだけ
      props.initNotificationAction();
      // リマインダー通知
      props.getAppendixReminderAction();
    } else {
      // スマホの場合の処理
      // 位置情報取得の開始
      props.startWatchAction();
    }
    this.isRender = false;
    this.isSetAgency = false;
  }

  componentDidMount() {
    const { fetchAgencyDefinitionAction } = this.props;
    fetchAgencyDefinitionAction();
    this.isRender = true;
  }

  shouldComponentUpdate() {
    // 何回もレンダリングする必要はないので最初の１回だけレンダリングする
    if (!this.isRender) {
      return true;
    }
    const { alertDefinition, setAlertDefinitionAction } = this.props;
    if (!this.isSetAgency && Object.keys(alertDefinition.codes).length > 0) {
      setAlertDefinitionAction(alertDefinition);
      this.isSetAgency = true;
    }
    return false;
  }

  render() {
    const NotFound = () => <h1>404 NotFound</h1>;

    // if (!window.loginUser) {
    if (!(window as any).loginUser) {
      window.location.href = './../pages';
    }

    // カレントページもしくは/pages/に直接アクセスした場合のリダイレクト
    const Startpage = () => <Navigate to="/pages/running-vehicles" replace />;
    const history = createHistory();

    // let alertMap: JSX.Element | string = (
    // アラートマップ画面
    const alertMap: any = (
      <Route
        path="/pages/alertmap"
        element={
          <LocationListener
            from={import('../pages/alertmap-analysis/OperationAnalysisContainer')}
            history={history}
          />
        }
      />
    );

    // 日野用全車コントロール画面
    const hino = window.loginUser.services.is_hino && (
      <Route
        path="/pages/hino"
        element={<LocationListener from={import('../pages/hino')} history={history} />}
      />
    );

    // 日野コンテナ画面
    const hinoContainer = window.loginUser.services.is_container && (
      <>
        <Route
          path="/pages/container-rtg"
          element={
            <LocationListener from={import('../pages/container-rtg/index')} history={history} />
          }
        />
        <Route
          path="/pages/container-gantry"
          element={
            <LocationListener from={import('../pages/container-gantry/index')} history={history} />
          }
        />
      </>
    );

    return (
      <>
        <MediaQuery query="(max-width: 767px)" data-test="mediaQuery">
          <Router>
            <Routes>
              <Route path="/" element={<Startpage />} />

              <Route path="/pages/" element={<Startpage />} />
              <Route
                path="/pages/running-vehicles"
                element={
                  <LocationListener
                    from={import('../pages/sp-running-vehicles')}
                    history={history}
                  />
                }
              />

              {hino}
              {hinoContainer}
            </Routes>
          </Router>
        </MediaQuery>
        <MediaQuery query="(min-width: 768px)" data-test="mediaQuery">
          <Router>
            <Routes>
              <Route path="/" element={<Startpage />} />

              <Route path="/pages/" element={<Startpage />} />

              <Route
                path="/pages/information"
                element={
                  <LocationListener from={import('../pages/information')} history={history} />
                }
              />
              <Route
                path="/pages/running-vehicles"
                element={
                  <LocationListener
                    from={import('../pages/running-vehicles/RunningVehiclesContainer')}
                    history={history}
                  />
                }
              />
              <Route
                path="/pages/assignment"
                element={
                  <LocationListener
                    from={import('../pages/calendar/Assignment')}
                    history={history}
                  />
                }
              />
              <Route
                path="/pages/reservations"
                element={
                  <LocationListener from={import('../pages/reservations')} history={history} />
                }
              />

              <Route
                path="/pages/setting"
                element={
                  <LocationListener from={import('../pages/setting/setting')} history={history} />
                }
              />

              <Route
                path="/pages/about"
                element={<LocationListener from={import('../pages/about')} history={history} />}
              />

              {alertMap}

              {hino}

              {hinoContainer}

              <Route
                path="/pages/employees"
                element={
                  <LocationListener from={import('../pages/employees/')} history={history} />
                }
              />
              <Route
                path="/pages/alerts"
                element={<LocationListener from={import('../pages/history')} history={history} />}
              />
              <Route
                path="/pages/offices"
                element={<LocationListener from={import('../pages/offices')} history={history} />}
              />
              <Route
                path="/pages/check-presets"
                element={
                  <LocationListener from={import('../pages/check-presets')} history={history} />
                }
              />
              <Route
                path="/pages/parent_routes"
                element={
                  <LocationListener from={import('../pages/parent-routes')} history={history} />
                }
              />
              <Route
                path="/pages/routes"
                element={<LocationListener from={import('../pages/routes')} history={history} />}
              />
              <Route
                path="/pages/trips"
                element={
                  <LocationListener from={import('../pages/trips/trips')} history={history} />
                }
              />

              <Route
                path="/pages/vehicles"
                element={<LocationListener from={import('../pages/vehicles/')} history={history} />}
              />
              <Route
                path="/pages/autodrive-dep"
                element={
                  <LocationListener from={import('../pages/autodrive-dep')} history={history} />
                }
              />
              <Route
                path="/pages/areas"
                element={
                  <LocationListener from={import('../pages/area-setting')} history={history} />
                }
              />
              <Route
                path="/pages/busstops"
                element={<LocationListener from={import('../pages/busstops')} history={history} />}
              />
              <Route
                path="/pages/courses"
                element={<LocationListener from={import('../pages/courses')} history={history} />}
              />
              <Route
                path="/pages/courses/detail"
                element={
                  <LocationListener
                    from={import('../pages/course-detail/courseDetail')}
                    history={history}
                  />
                }
              />

              <Route
                path="/pages/agency"
                element={<LocationListener from={import('../pages/agency')} history={history} />}
              />

              <Route
                path="/pages/trip-lines"
                element={
                  <LocationListener from={import('../pages/trip-lines')} history={history} />
                }
              />

              <Route
                path="/pages/reservation-presets"
                element={
                  <LocationListener
                    from={import('../pages/reservation-presets')}
                    history={history}
                  />
                }
              />

              <Route
                path="/pages/day-reservation-presets"
                element={
                  <LocationListener
                    from={import('../pages/day-reservation-presets')}
                    history={history}
                  />
                }
              />

              <Route
                path="/pages/account"
                element={<LocationListener from={import('../pages/account')} history={history} />}
              />

              <Route path="/pages/*" element={NotFound()} />

              <Route path="/*" element={NotFound()} />
            </Routes>
          </Router>
        </MediaQuery>
      </>
    );
  }
}

export interface DispatchToProps {
  initTwilioAction: () => any;
  initNotificationAction: () => any;
  setAlertDefinitionAction: (alertDefinition: any) => any;
  fetchAgencyDefinitionAction: () => any;
  startWatchAction: () => any;
  getAppendixReminderAction: () => void;
}
export interface StateToProps {
  alertDefinition: any;
}

export default PageRouter;
